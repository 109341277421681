import Header from "../../../../components/header/Header";
import bgGradient from "../../../../images/bgrg.png";
import success from "../../../../images/success.json";
import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom";

interface SuccessPasswordProps {}

const SuccessPassword: React.FunctionComponent<SuccessPasswordProps> = () => {
  const navigate = useNavigate();
  const handleClick = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    navigate("/facilitator/signin");
  };
  return (
    <div className="onbdMain">
      <Header />
      <div className="onbdCont">
        <img src={bgGradient} alt="" className="grd" />
        <div className="whtCont">
          <h4>Password reset successfully!</h4>
          <div className="lott">
            <Lottie animationData={success} />
          </div>
          <h3>You’ve got a new password now!</h3>
          <p className="ver">
            Login to your Rankaque account with your new password.
          </p>
          <form onSubmit={(e) => handleClick(e)}>
            <button>Proceed to Login</button>
          </form>
          <div className="tnc">
            <p>Terms and Condtions</p>
            <span>.</span>
            <p>Privacy Policy</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessPassword;
