import AttClassSidebar from "./AttClassSidebar";
import { Checkbox, Input, Skeleton } from "antd";
import profileImg from "../../../../images/defaultPic.png";
import ClassLectureDetails from "../../../../components/classLectureDetails/ClassLectureDetails";
import QuestionCard from "../../../../components/questionCard/QuestionCard";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { notification } from "antd";
import React, { useEffect, useState, useCallback } from "react";
import { selectAccessToken, selectRefreshToken } from "../../../../store/Auth";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import Lottie from "lottie-react";
import empty from "../../../../images/empty.json";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { login } from "../../../../store/Auth";

interface PostQuestionProps {}

type Question = {
  has_user_voted: boolean;
  id: number;
  is_anonymous: boolean;
  is_answered: boolean;
  lecture: number;
  number_of_votes: number;
  question_by: {} | null;
  question_ranking: number;
  question_text: string;
};

const PostQuestion: React.FunctionComponent<PostQuestionProps> = () => {
  const { id } = useParams();
  const token = useSelector(selectAccessToken);
  const refreshToken = useSelector(selectRefreshToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [lecture, setLecture] = useState<any>();

  const [question, setQuestion] = useState<string>("");

  const [isPostLoading, setIsPostLoading] = useState<boolean>(false);
  const [isQuestionsLoading, setIsQuestionsLoading] = useState<boolean>(true);

  const [questions, setQuestions] = useState<Question[]>([]);
  const [activeID, setActiveID] = useState<number>(0);

  const [isAnonymous, setIsAnonymous] = useState<boolean>(false);

  const onChange = (e: CheckboxChangeEvent) => {
    setIsAnonymous(e.target.checked);
  };

  const refreshAccessToken = useCallback(() => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/users/refresh-token`,
      data: {
        refresh: refreshToken,
      },
    })
      .then((res) => {
        dispatch(login(res.data));
      })
      .catch((err) => {
        navigate("/attendee/signin");
      });
  }, [dispatch, navigate, refreshToken]);

  useEffect(() => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/lectures/${id}/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setLecture(res.data.data);
        setQuestions(res.data.data.questions);
        setIsQuestionsLoading(false);
      })
      .catch((err) => {
        setIsQuestionsLoading(false);
        if (err.response.status === 401) {
          refreshAccessToken();
          return;
        }
        notification.error({
          message: "Error fetching lecture",
          description: "Oops! there was an error fetching lecture details",
        });
      });
  }, [id, token, refreshAccessToken]);

  const refreshFeed = () => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/lectures/${id}/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setQuestions(res.data.data.questions);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          refreshAccessToken();
          return;
        }
      });
  };

  useEffect(() => {
    const timer = setTimeout(() => refreshFeed(), 5000);
    return () => clearTimeout(timer);
  });

  const postQuestion = () => {
    if (question.length > 0) {
      setQuestion("");
      setIsPostLoading(true);
      axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/questions/`,
        data: {
          question_text: question,
          is_anonymous: isAnonymous,
          lecture: id,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          setIsPostLoading(false);
          setQuestions([...questions, res.data.data.question]);
          notification.success({
            message: "Question posted",
            description: "Your question has been posted to the class",
          });
        })
        .catch((err) => {
          setIsPostLoading(false);
          notification.error({
            message: "Error posting question",
            description: "Oops! there was an error posting your question",
          });
        });
    }
  };

  return (
    <div className="flexClass">
      <AttClassSidebar />
      <div className="mainClass">
        <div className="leftClass">
          <Input
            type="search"
            name="search"
            id="search"
            placeholder="Search..."
            suffix={
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17Z"
                  stroke="#4A4E57"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15 15L21 21"
                  stroke="#4A4E57"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            }
          />
          <div className="postDiv">
            <h3>Post your question</h3>
            <div className="flexPst">
              <img src={profileImg} alt="" />
              <div className="questTab">
                <textarea
                  name="question"
                  id="question"
                  cols={30}
                  rows={3}
                  placeholder="What would you like to ask?"
                  onChange={(e) => setQuestion(e.target.value)}
                  value={question}
                  maxLength={199}
                ></textarea>
                <Checkbox onChange={onChange}>Keep me anonymous</Checkbox>
                <button onClick={postQuestion}>
                  {!isPostLoading ? "Post Question" : <LoadingOutlined />}
                </button>
              </div>
            </div>
          </div>
          <div className="quesCont">
            <h2>Questions asked in this class</h2>
            {isQuestionsLoading ? (
              <Skeleton className="pad20" paragraph={{ rows: 5 }} active />
            ) : questions.length > 0 ? (
              <div className="questionsWrapper">
                {questions.map((question: any) => (
                  <QuestionCard
                    key={question.id}
                    question={question.question_text}
                    author={question.question_by}
                    time={moment(question.time_of_question, "HH:mm:ss").format(
                      "hh:mm A"
                    )}
                    isFacilitator={false}
                    voteStatus={question.has_user_voted}
                    setActiveID={setActiveID}
                    activeID={activeID}
                    id={question.id}
                    setQuestions={setQuestions}
                    picture={question?.question_by?.profile?.picture}
                  />
                ))}
              </div>
            ) : (
              <div className="emp">
                <Lottie animationData={empty} />
                <p>No questions yet.</p>
              </div>
            )}
          </div>
        </div>
        <div className="rightClass">
          <ClassLectureDetails lecture={lecture} />
        </div>
      </div>
    </div>
  );
};

export default PostQuestion;
