import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {},
};

const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = { ...state.user, ...action.payload };
    },
    logOutUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

export default UserSlice.reducer;

export const { setUser, logOutUser } = UserSlice.actions;

export const selectUser = (state: {
  user: {
    user: {
      email: string;
      first_name: string;
      last_name: string;
      phone_number: number;
      user_type: number;
      profile: {
        picture: string;
      };
    };
  };
}) => state.user.user;
