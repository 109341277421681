import logo from "../../../../images/logo2.png";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import { logout } from "../../../../store/Auth";
import { logOutUser, selectUser } from "../../../../store/User";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import profileImg from "../../../../images/defaultPic.png";
import { useState } from "react";
import { Modal } from "antd";

interface FacClassSidebarProps {}

const FacClassSidebar: React.FunctionComponent<FacClassSidebarProps> = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const isMobile = window.innerWidth <= 768;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const user = useSelector(selectUser);

  const logoutClass = () => {
    dispatch(logout(null));
    dispatch(logOutUser(null));
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  if (isMobile) {
    return (
      <div className="mobileBar">
        <div className="topbar">
          <div className="brLft">
            <button onClick={showModal}>
              <svg
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 34H38"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10 24H38"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10 14H38"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            <img src={logo} alt="" />
          </div>
          <div className="brRght">
            <button>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 17V16C8.44772 16 8 16.4477 8 17H9ZM15 17H16C16 16.4477 15.5523 16 15 16V17ZM9 18H15V16H9V18ZM14 17V18H16V17H14ZM10 18V17H8V18H10ZM12 20C10.8954 20 10 19.1046 10 18H8C8 20.2091 9.79086 22 12 22V20ZM14 18C14 19.1046 13.1046 20 12 20V22C14.2091 22 16 20.2091 16 18H14Z"
                  fill="white"
                />
                <path
                  d="M4.29289 14.7071L3.58579 14L3.58579 14L4.29289 14.7071ZM4.80372 14.1963L5.51082 14.9034L5.51082 14.9034L4.80372 14.1963ZM19.1963 14.1963L19.9034 13.4892L19.9034 13.4892L19.1963 14.1963ZM19.7071 14.7071L20.4142 14L20.4142 14L19.7071 14.7071ZM12 3L12 4L12 3ZM19 16H5V18H19V16ZM5 16V15.4142H3V16H5ZM5 15.4142L5.51082 14.9034L4.09661 13.4892L3.58579 14L5 15.4142ZM6 13.7224V10H4V13.7224H6ZM18 10V13.7224H20V10H18ZM18.4892 14.9034L19 15.4142L20.4142 14L19.9034 13.4892L18.4892 14.9034ZM19 15.4142V16H21V15.4142H19ZM19 15.4142L19 15.4142H21C21 14.8838 20.7893 14.3751 20.4142 14L19 15.4142ZM18 13.7224C18 14.1653 18.176 14.5901 18.4892 14.9034L19.9034 13.4892C19.9653 13.551 20 13.6349 20 13.7224H18ZM20 10C20 5.58171 16.4183 1.99999 12 2L12 4C15.3137 3.99999 18 6.68629 18 10H20ZM6 10C6 6.6863 8.68628 4.00001 12 4L12 2C7.58171 2.00001 4 5.58173 4 10H6ZM5.51082 14.9034C5.82404 14.5902 6 14.1654 6 13.7224H4C4 13.635 4.03475 13.5511 4.09661 13.4892L5.51082 14.9034ZM5 15.4142H5L3.58579 14C3.21071 14.3751 3 14.8838 3 15.4142H5ZM5 16H3C3 17.1046 3.89543 18 5 18V16ZM19 18C20.1046 18 21 17.1046 21 16H19V18Z"
                  fill="white"
                />
              </svg>
            </button>
            <img
              src={
                user?.profile?.picture !== null
                  ? user?.profile?.picture
                  : profileImg
              }
              className="prpic"
              alt=""
            />
          </div>
        </div>
        <Modal
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
          closable={false}
          style={{
            margin: "0px",
            top: "0px",
            padding: "0px",
          }}
          bodyStyle={{
            height: "100vh",
            padding: "0px",
          }}
          width="70%"
        >
          <div className="navBarMb">
            <div className="flxedn">
              <button className="close" onClick={handleCancel}>
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M34.9999 34.9999L5 5"
                    stroke="#3E4047"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M35.0001 5L5 35.0001"
                    stroke="#3E4047"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
            <div className="navs">
              <Link to={`/facilitator/classroom/feeds/${id}`}>
                <button
                  className={
                    location.pathname === `/facilitator/classroom/feeds/${id}`
                      ? "active"
                      : ""
                  }
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.18497 19.605L7.7207 18.7606C7.46683 18.5996 7.15397 18.5613 6.86874 18.6563L7.18497 19.605ZM3 21L2.05132 20.6838C1.93154 21.0431 2.02506 21.4393 2.29289 21.7071C2.56073 21.9749 2.95689 22.0685 3.31623 21.9487L3 21ZM4.39499 16.815L5.34367 17.1313C5.43875 16.846 5.40045 16.5332 5.23938 16.2793L4.39499 16.815ZM12 22C17.5228 22 22 17.5228 22 12H20C20 16.4183 16.4183 20 12 20V22ZM6.64923 20.4494C8.19708 21.4315 10.0337 22 12 22V20C10.4242 20 8.95764 19.5454 7.7207 18.7606L6.64923 20.4494ZM6.86874 18.6563L2.68377 20.0513L3.31623 21.9487L7.5012 20.5537L6.86874 18.6563ZM3.94868 21.3162L5.34367 17.1313L3.44631 16.4988L2.05132 20.6838L3.94868 21.3162ZM2 12C2 13.9663 2.56855 15.8029 3.5506 17.3508L5.23938 16.2793C4.45458 15.0424 4 13.5758 4 12H2ZM12 2C6.47715 2 2 6.47715 2 12H4C4 7.58172 7.58172 4 12 4V2ZM22 12C22 6.47715 17.5228 2 12 2V4C16.4183 4 20 7.58172 20 12H22Z"
                      fill="#C3C7CC"
                    />
                    <path
                      d="M16 12H16.002V12.002H16V12Z"
                      stroke="#C3C7CC"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12 12H12.002V12.002H12V12Z"
                      stroke="#C3C7CC"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8 12H8.002V12.002H8V12Z"
                      stroke="#C3C7CC"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span>Feeds</span>
                </button>
              </Link>
              <Link to={`/facilitator/classroom/ranked-questions/${id}`}>
                <button
                  className={
                    location.pathname ===
                    `/facilitator/classroom/ranked-questions/${id}`
                      ? "active"
                      : ""
                  }
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 11H10C10 10.4477 9.55228 10 9 10V11ZM9 20V21C9.55228 21 10 20.5523 10 20H9ZM3 20H2C2 20.5523 2.44772 21 3 21V20ZM4 12H9V10H4V12ZM8 11V20H10V11H8ZM9 19H3V21H9V19ZM4 20V12H2V20H4ZM4 10C2.89543 10 2 10.8954 2 12H4V12V10Z"
                      fill="#C3C7CC"
                    />
                    <path
                      d="M15 8V7C14.4477 7 14 7.44772 14 8H15ZM21 20V21C21.5523 21 22 20.5523 22 20H21ZM15 20H14C14 20.5523 14.4477 21 15 21V20ZM15 9H20V7H15V9ZM20 9V20H22V9H20ZM21 19H15V21H21V19ZM16 20V8H14V20H16ZM20 9H22C22 7.89543 21.1046 7 20 7V9Z"
                      fill="#C3C7CC"
                    />
                    <path
                      d="M15 20V21C15.5523 21 16 20.5523 16 20H15ZM9 20H8C8 20.5523 8.44772 21 9 21V20ZM10 5H14V3H10V5ZM14 5V20H16V5H14ZM15 19H9V21H15V19ZM10 20V5H8V20H10ZM14 5H16C16 3.89543 15.1046 3 14 3V5ZM10 3C8.89543 3 8 3.89543 8 5H10V3Z"
                      fill="#C3C7CC"
                    />
                  </svg>

                  <span>Ranked</span>
                </button>
              </Link>
              <Link to={`/facilitator/classroom/answered-questions/${id}`}>
                <button
                  className={
                    location.pathname ===
                    `/facilitator/classroom/answered-questions/${id}`
                      ? "active"
                      : location.pathname === "/facilitator/edit-profile"
                      ? "active"
                      : ""
                  }
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.18497 19.605L7.7207 18.7606C7.46683 18.5996 7.15397 18.5613 6.86874 18.6563L7.18497 19.605ZM3 21L2.05132 20.6838C1.93154 21.0431 2.02506 21.4393 2.29289 21.7071C2.56073 21.9749 2.95689 22.0685 3.31623 21.9487L3 21ZM4.39499 16.815L5.34367 17.1313C5.43875 16.846 5.40045 16.5332 5.23938 16.2793L4.39499 16.815ZM12 22C17.5228 22 22 17.5228 22 12H20C20 16.4183 16.4183 20 12 20V22ZM6.64923 20.4494C8.19708 21.4315 10.0337 22 12 22V20C10.4242 20 8.95764 19.5454 7.7207 18.7606L6.64923 20.4494ZM6.86874 18.6563L2.68377 20.0513L3.31623 21.9487L7.5012 20.5537L6.86874 18.6563ZM3.94868 21.3162L5.34367 17.1313L3.44631 16.4988L2.05132 20.6838L3.94868 21.3162ZM2 12C2 13.9663 2.56855 15.8029 3.5506 17.3508L5.23938 16.2793C4.45458 15.0424 4 13.5758 4 12H2ZM12 2C6.47715 2 2 6.47715 2 12H4C4 7.58172 7.58172 4 12 4V2ZM22 12C22 6.47715 17.5228 2 12 2V4C16.4183 4 20 7.58172 20 12H22Z"
                      fill="#C3C7CC"
                    />
                    <path
                      d="M15.7071 10.7071C16.0976 10.3166 16.0976 9.68342 15.7071 9.29289C15.3166 8.90237 14.6834 8.90237 14.2929 9.29289L15.7071 10.7071ZM11 14L10.2929 14.7071C10.6834 15.0976 11.3166 15.0976 11.7071 14.7071L11 14ZM9.70711 11.2929C9.31658 10.9024 8.68342 10.9024 8.29289 11.2929C7.90237 11.6834 7.90237 12.3166 8.29289 12.7071L9.70711 11.2929ZM14.2929 9.29289L10.2929 13.2929L11.7071 14.7071L15.7071 10.7071L14.2929 9.29289ZM11.7071 13.2929L9.70711 11.2929L8.29289 12.7071L10.2929 14.7071L11.7071 13.2929Z"
                      fill="#C3C7CC"
                    />
                  </svg>
                  <span>Answered</span>
                </button>
              </Link>
              <button onClick={logoutClass}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.2922 24.2929C18.9017 24.6834 18.9017 25.3166 19.2922 25.7071C19.6828 26.0976 20.3159 26.0976 20.7065 25.7071L19.2922 24.2929ZM24.9993 20L25.7065 20.7071C25.894 20.5196 25.9993 20.2652 25.9993 20C25.9993 19.7348 25.894 19.4804 25.7065 19.2929L24.9993 20ZM20.7065 14.2929C20.3159 13.9024 19.6828 13.9024 19.2922 14.2929C18.9017 14.6834 18.9017 15.3166 19.2922 15.7071L20.7065 14.2929ZM6.66602 19C6.11373 19 5.66602 19.4477 5.66602 20C5.66602 20.5523 6.11373 21 6.66602 21L6.66602 19ZM20.7065 25.7071L25.7065 20.7071L24.2922 19.2929L19.2922 24.2929L20.7065 25.7071ZM25.7065 19.2929L20.7065 14.2929L19.2922 15.7071L24.2922 20.7071L25.7065 19.2929ZM6.66602 21L24.9993 21V19L6.66602 19L6.66602 21Z"
                    fill="#787F89"
                  />
                  <path
                    d="M15 11.668V8.33464C15 7.41416 15.7462 6.66797 16.6667 6.66797H31.6667C32.5871 6.66797 33.3333 7.41416 33.3333 8.33464V31.668C33.3333 32.5884 32.5871 33.3346 31.6667 33.3346H16.6667C15.7462 33.3346 15 32.5884 15 31.668V28.3346"
                    stroke="#787F89"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span>Logout</span>
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }

  return (
    <div className="clsSidebar">
      <div className="logo">
        <img
          src={logo}
          alt="rankaque"
          onClick={() => navigate("/facilitator/dashboard")}
        />
      </div>
      <div className="classNavs">
        <button
          className={
            location.pathname === `/facilitator/classroom/feeds/${id}`
              ? "active"
              : ""
          }
          onClick={() => navigate(`/facilitator/classroom/feeds/${id}`)}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.18497 19.605L7.7207 18.7606C7.46683 18.5996 7.15397 18.5613 6.86874 18.6563L7.18497 19.605ZM3 21L2.05132 20.6838C1.93154 21.0431 2.02506 21.4393 2.29289 21.7071C2.56073 21.9749 2.95689 22.0685 3.31623 21.9487L3 21ZM4.39499 16.815L5.34367 17.1313C5.43875 16.846 5.40045 16.5332 5.23938 16.2793L4.39499 16.815ZM12 22C17.5228 22 22 17.5228 22 12H20C20 16.4183 16.4183 20 12 20V22ZM6.64923 20.4494C8.19708 21.4315 10.0337 22 12 22V20C10.4242 20 8.95764 19.5454 7.7207 18.7606L6.64923 20.4494ZM6.86874 18.6563L2.68377 20.0513L3.31623 21.9487L7.5012 20.5537L6.86874 18.6563ZM3.94868 21.3162L5.34367 17.1313L3.44631 16.4988L2.05132 20.6838L3.94868 21.3162ZM2 12C2 13.9663 2.56855 15.8029 3.5506 17.3508L5.23938 16.2793C4.45458 15.0424 4 13.5758 4 12H2ZM12 2C6.47715 2 2 6.47715 2 12H4C4 7.58172 7.58172 4 12 4V2ZM22 12C22 6.47715 17.5228 2 12 2V4C16.4183 4 20 7.58172 20 12H22Z"
              fill="#C3C7CC"
            />
            <path
              d="M16 12H16.002V12.002H16V12Z"
              stroke="#C3C7CC"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 12H12.002V12.002H12V12Z"
              stroke="#C3C7CC"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8 12H8.002V12.002H8V12Z"
              stroke="#C3C7CC"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span>Feeds</span>
        </button>
        <button
          onClick={() =>
            navigate(`/facilitator/classroom/ranked-questions/${id}`)
          }
          className={
            location.pathname ===
            `/facilitator/classroom/ranked-questions/${id}`
              ? "active"
              : ""
          }
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 11H10C10 10.4477 9.55228 10 9 10V11ZM9 20V21C9.55228 21 10 20.5523 10 20H9ZM3 20H2C2 20.5523 2.44772 21 3 21V20ZM4 12H9V10H4V12ZM8 11V20H10V11H8ZM9 19H3V21H9V19ZM4 20V12H2V20H4ZM4 10C2.89543 10 2 10.8954 2 12H4V12V10Z"
              fill="#C3C7CC"
            />
            <path
              d="M15 8V7C14.4477 7 14 7.44772 14 8H15ZM21 20V21C21.5523 21 22 20.5523 22 20H21ZM15 20H14C14 20.5523 14.4477 21 15 21V20ZM15 9H20V7H15V9ZM20 9V20H22V9H20ZM21 19H15V21H21V19ZM16 20V8H14V20H16ZM20 9H22C22 7.89543 21.1046 7 20 7V9Z"
              fill="#C3C7CC"
            />
            <path
              d="M15 20V21C15.5523 21 16 20.5523 16 20H15ZM9 20H8C8 20.5523 8.44772 21 9 21V20ZM10 5H14V3H10V5ZM14 5V20H16V5H14ZM15 19H9V21H15V19ZM10 20V5H8V20H10ZM14 5H16C16 3.89543 15.1046 3 14 3V5ZM10 3C8.89543 3 8 3.89543 8 5H10V3Z"
              fill="#C3C7CC"
            />
          </svg>

          <span>Ranked</span>
        </button>
        <button
          onClick={() =>
            navigate(`/facilitator/classroom/answered-questions/${id}`)
          }
          className={
            location.pathname ===
            `/facilitator/classroom/answered-questions/${id}`
              ? "active"
              : ""
          }
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.18497 19.605L7.7207 18.7606C7.46683 18.5996 7.15397 18.5613 6.86874 18.6563L7.18497 19.605ZM3 21L2.05132 20.6838C1.93154 21.0431 2.02506 21.4393 2.29289 21.7071C2.56073 21.9749 2.95689 22.0685 3.31623 21.9487L3 21ZM4.39499 16.815L5.34367 17.1313C5.43875 16.846 5.40045 16.5332 5.23938 16.2793L4.39499 16.815ZM12 22C17.5228 22 22 17.5228 22 12H20C20 16.4183 16.4183 20 12 20V22ZM6.64923 20.4494C8.19708 21.4315 10.0337 22 12 22V20C10.4242 20 8.95764 19.5454 7.7207 18.7606L6.64923 20.4494ZM6.86874 18.6563L2.68377 20.0513L3.31623 21.9487L7.5012 20.5537L6.86874 18.6563ZM3.94868 21.3162L5.34367 17.1313L3.44631 16.4988L2.05132 20.6838L3.94868 21.3162ZM2 12C2 13.9663 2.56855 15.8029 3.5506 17.3508L5.23938 16.2793C4.45458 15.0424 4 13.5758 4 12H2ZM12 2C6.47715 2 2 6.47715 2 12H4C4 7.58172 7.58172 4 12 4V2ZM22 12C22 6.47715 17.5228 2 12 2V4C16.4183 4 20 7.58172 20 12H22Z"
              fill="#C3C7CC"
            />
            <path
              d="M15.7071 10.7071C16.0976 10.3166 16.0976 9.68342 15.7071 9.29289C15.3166 8.90237 14.6834 8.90237 14.2929 9.29289L15.7071 10.7071ZM11 14L10.2929 14.7071C10.6834 15.0976 11.3166 15.0976 11.7071 14.7071L11 14ZM9.70711 11.2929C9.31658 10.9024 8.68342 10.9024 8.29289 11.2929C7.90237 11.6834 7.90237 12.3166 8.29289 12.7071L9.70711 11.2929ZM14.2929 9.29289L10.2929 13.2929L11.7071 14.7071L15.7071 10.7071L14.2929 9.29289ZM11.7071 13.2929L9.70711 11.2929L8.29289 12.7071L10.2929 14.7071L11.7071 13.2929Z"
              fill="#C3C7CC"
            />
          </svg>
          <span>Answered</span>
        </button>
        <button onClick={logoutClass}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.2922 24.2929C18.9017 24.6834 18.9017 25.3166 19.2922 25.7071C19.6828 26.0976 20.3159 26.0976 20.7065 25.7071L19.2922 24.2929ZM24.9993 20L25.7065 20.7071C25.894 20.5196 25.9993 20.2652 25.9993 20C25.9993 19.7348 25.894 19.4804 25.7065 19.2929L24.9993 20ZM20.7065 14.2929C20.3159 13.9024 19.6828 13.9024 19.2922 14.2929C18.9017 14.6834 18.9017 15.3166 19.2922 15.7071L20.7065 14.2929ZM6.66602 19C6.11373 19 5.66602 19.4477 5.66602 20C5.66602 20.5523 6.11373 21 6.66602 21L6.66602 19ZM20.7065 25.7071L25.7065 20.7071L24.2922 19.2929L19.2922 24.2929L20.7065 25.7071ZM25.7065 19.2929L20.7065 14.2929L19.2922 15.7071L24.2922 20.7071L25.7065 19.2929ZM6.66602 21L24.9993 21V19L6.66602 19L6.66602 21Z"
              fill="#9DA3AB"
            />
            <path
              d="M15 11.6667V8.33341C15 7.41294 15.7462 6.66675 16.6667 6.66675H31.6667C32.5871 6.66675 33.3333 7.41294 33.3333 8.33341V31.6667C33.3333 32.5872 32.5871 33.3334 31.6667 33.3334H16.6667C15.7462 33.3334 15 32.5872 15 31.6667V28.3334"
              stroke="#9DA3AB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <span>Logout</span>
        </button>
      </div>
    </div>
  );
};

export default FacClassSidebar;
