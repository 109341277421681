import FacilitatorSidebar from "../../../components/FacilitatorSidebar/FacilitatorSidebar";
import { notification, Table } from "antd";
import type { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState, useCallback } from "react";
import Lottie from "lottie-react";
import empty from "../../../images/empty.json";
import { Modal, Skeleton } from "antd";
import NewLecture from "../../../components/modalcontent/NewLecture";
import axios from "axios";
import { selectAccessToken, selectRefreshToken } from "../../../store/Auth";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import SuccessModal from "../../../components/modalcontent/SuccessModal";
import { useNavigate } from "react-router-dom";
import { login } from "../../../store/Auth";

interface FacilitatorLecturesProps {}

interface DataType {
  key: string;
  lecture_id: string;
  title: string;
  created_date: string;
  lecturer: string;
  status: string;
  id: string;
}

const FacilitatorLectures: React.FunctionComponent<
  FacilitatorLecturesProps
> = () => {
  const token = useSelector(selectAccessToken);
  const refreshToken = useSelector(selectRefreshToken);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isTableLoading, setIsTableLoading] = useState<boolean>(true);
  const [successLectureID, setSuccessLectureID] = useState<string>("");
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSuccessCancel = () => {
    setIsSuccess(false);
  };

  const refreshAccessToken = useCallback(() => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/users/refresh-token`,
      data: {
        refresh: refreshToken,
      },
    })
      .then((res) => {
        dispatch(login(res.data));
      })
      .catch((err) => {
        navigate("/attendee/signin");
      });
  }, [dispatch, navigate, refreshToken]);

  const columns: ColumnsType<DataType> = [
    {
      title: "LECTURE ID",
      dataIndex: "lecture_id",
      key: "lecture_id",
    },
    {
      title: "TITLE",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "TIME SCHEDULE",
      dataIndex: "lecture_date",
      key: "created_on",
      render: (item) => <>{moment(item).format("ll")}</>,
    },
    {
      title: "FACILITATOR",
      dataIndex: "lecturer",
      key: "lecturer",
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <span
          className={
            status === 1
              ? "draft"
              : status === 2
              ? "published"
              : status === 3
              ? "unpublished"
              : ""
          }
        >
          {status === 1
            ? "Draft"
            : status === 2
            ? "Published"
            : status === 3
            ? "Unpublished"
            : ""}
        </span>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (item, record) => (
        <button
          onClick={() => navigate(`/facilitator/lecture/${record.id}`)}
          className="vDeets"
        >
          View Details
        </button>
      ),
    },
  ];

  const [tableData, setTableData] = useState<DataType[]>([]);

  useEffect(() => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/lectures/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setTableData(res.data.data);
        setIsTableLoading(false);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          refreshAccessToken();
          return;
        }
        notification.error({
          message: "Error fetching lectures",
          description: "Oops! there was an error fetching your lectures",
        });
      });
  }, [token, refreshAccessToken]);

  return (
    <div className="dashCont">
      <FacilitatorSidebar />
      <div className="flexMain">
        <div className="lectureCont">
          <div className="topLec">
            <h2>Lectures</h2>
            <button onClick={showModal}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 12H18"
                  stroke="#2563EB"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12 18L12 6"
                  stroke="#2563EB"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span>Add New</span>
            </button>
          </div>
          <div className="tableCont">
            {isTableLoading ? (
              <Skeleton className="padSk" active paragraph={{ rows: 6 }} />
            ) : tableData.length > 0 ? (
              <Table
                columns={columns}
                dataSource={tableData}
                rowKey={"lecture_id"}
              />
            ) : (
              <div className="emp">
                <Lottie animationData={empty} />
                <p>No lecture created yet.</p>
                <button onClick={showModal}>Create a Lecture</button>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        title={null}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        closable={false}
        zIndex={1}
        width={"600px"}
      >
        <NewLecture
          setTableData={setTableData}
          setIsModalVisible={setIsModalVisible}
          setIsSuccess={setIsSuccess}
          setSuccessLectureID={setSuccessLectureID}
        />
      </Modal>
      <Modal
        title={null}
        visible={isSuccess}
        onCancel={handleSuccessCancel}
        footer={null}
        closable={false}
        zIndex={1}
      >
        <SuccessModal successLectureID={successLectureID} />
      </Modal>
    </div>
  );
};

export default FacilitatorLectures;
