import boyImg from "../../images/boy.png";
import girlImg from "../../images/girls.png";
import "./about.scss";
import { Element } from "react-scroll";

interface AboutProps {}

const About: React.FunctionComponent<AboutProps> = () => {
  return (
    <>
      <Element name="about"></Element>
      <div className="mainAbt">
        <div className="flxAbt tw">
          <div className="leftAbt">
            <p>
              The average attendee/facilitator attendance is cautious to ask
              questions during lectures due to socio-cultural expectations or
              because most lectures are one-way exchange
            </p>
          </div>
          <div className="rightAbt" data-aos="fade-up">
            <img src={boyImg} alt="" />
          </div>
        </div>
        <div className="flxAbt">
          <div className="leftAbt" data-aos="fade-up">
            <img src={girlImg} alt="" />
          </div>
          <div className="rightAbt">
            <p>
              It’s easy to think that same learners are afraid to speak out, but
              keep in mind that others may come from nations or cultures where
              speaking only when expressly addressed in the norm
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
