import empty from "../../../images/empty.json";
import Lottie from "lottie-react";
import AttendeeSidebar from "../../../components/AttendeeSidebar/AttendeeSidebar";
import AttendeeTopbar from "../../../components/AttendeeTopbar/AttendeeTopbar";
import moment from "moment";
import axios from "axios";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { notification, Skeleton } from "antd";
import React, { useEffect, useState, useCallback } from "react";
import { selectAccessToken, selectRefreshToken } from "../../../store/Auth";
import { selectUser } from "../../../store/User";

import { useSelector, useDispatch } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import { login } from "../../../store/Auth";

interface ViewLectureProps {}

interface Lecture {
  title: string;
  description: string;
  lecture_time: string;
  lecture_date: string;
  lecture_keys: { id: number; keyword: string }[];
  lecturer: string;
  questions: [];
  in_class: boolean;
  status: number;
}

interface User {
  email: string;
  first_name: string;
  last_name: string;
  phone_number: number;
  user_type: number;
}

const ViewLecture: React.FunctionComponent<ViewLectureProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user: User = useSelector(selectUser);
  const { id } = useParams();
  const token = useSelector(selectAccessToken);
  const refreshToken = useSelector(selectRefreshToken);
  const dispatch = useDispatch();
  const [lecture, setLecture] = useState<Lecture>();
  const [isLoading, setIsloading] = useState<boolean>(true);

  const [isJoinLecLoading, setIsJoinLecloading] = useState<boolean>(false);
  const [isLeaveLecLoading, setIsLeaveLecloading] = useState<boolean>(false);
  const [inClass, setInClass] = useState<boolean>(false);

  const refreshAccessToken = useCallback(() => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/users/refresh-token`,
      data: {
        refresh: refreshToken,
      },
    })
      .then((res) => {
        dispatch(login(res.data));
      })
      .catch((err) => {
        navigate("/attendee/signin");
      });
  }, [dispatch, navigate, refreshToken]);

  const fetchLecture = () => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/lectures/check-user-in-class`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        lecture: id,
      },
    })
      .then((res) => {
        setInClass(res.data.data.in_class);
        setIsloading(false);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          refreshAccessToken();
          return;
        }
        notification.error({
          message: "Error fetching lecture",
          description: "Oops! there was an error fetching lecture details",
        });
      });
  };

  useEffect(() => {
    user.user_type !== 2 &&
      navigate("/attendee/signin", {
        state: {
          from: location,
        },
        replace: true,
      });
  }, [user, navigate, location]);

  useEffect(() => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/lectures/check-user-in-class`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        lecture: id,
      },
    })
      .then((res) => {
        setInClass(res.data.data.in_class);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          refreshAccessToken();
          return;
        }
      });
  }, [token, id, refreshAccessToken]);

  useEffect(() => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/lectures/${id}/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setLecture(res.data.data);
        setIsloading(false);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          refreshAccessToken();
          return;
        }
        notification.error({
          message: "Error fetching lecture",
          description: "Oops! there was an error fetching lecture details",
        });
      });
  }, [id, token, refreshAccessToken]);

  const joinLecture = () => {
    setIsJoinLecloading(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/lectures/join-leave-lecture`,
      data: {
        lecture: id,
        in_class: true,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        fetchLecture();
        setIsJoinLecloading(false);
        notification.success({
          message: "Joined Lecture",
          description: `You have successfully joined the ${
            lecture?.title || ""
          } lecture`,
        });
      })
      .catch((err) => {
        setIsJoinLecloading(false);
        if (err.response.status === 401) {
          refreshAccessToken();
          return;
        }
        notification.error({
          message: "Error joining lecture",
          description: "Oops! there was an error joining this lecture",
        });
      });
  };

  const leaveLecture = () => {
    setIsLeaveLecloading(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/lectures/join-leave-lecture`,
      data: {
        lecture: id,
        in_class: false,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        fetchLecture();
        setIsLeaveLecloading(false);
        notification.success({
          message: "Joined Lecture",
          description: `You have successfully left the ${
            lecture?.title || ""
          } lecture`,
        });
      })
      .catch((err) => {
        setIsLeaveLecloading(false);
        if (err.response.status === 401) {
          refreshAccessToken();
          return;
        }
        notification.error({
          message: "Error joining lecture",
          description: "Oops! there was an error leaving this lecture",
        });
      });
  };

  return (
    <div className="dashCont">
      <AttendeeSidebar />
      <div className="flexMain">
        <div className="attDashboard">
          <AttendeeTopbar />
          {isLoading ? (
            <Skeleton className="padSkLd" active paragraph={{ rows: 15 }} />
          ) : (
            <div className="flexMain">
              <div className="fstMain">
                <h1>Lecture Details</h1>
                <label htmlFor="title">TITLE</label>
                <h3>{lecture?.title}</h3>
                <div className="flCol">
                  <label htmlFor="desc">DESCRIPTION</label>
                  <textarea
                    readOnly
                    name="desc"
                    id="desc"
                    cols={30}
                    rows={5}
                    defaultValue={lecture?.description}
                  ></textarea>
                </div>
                <label htmlFor="time">TIME SCHEDULE</label>
                <div className="TIMDcNT">
                  <div className="date">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19 4H5C4.44772 4 4 4.44772 4 5V19C4 19.5523 4.44772 20 5 20H19C19.5523 20 20 19.5523 20 19V5C20 4.44772 19.5523 4 19 4Z"
                        stroke="#1D4ED8"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16 16H16.002V16.002H16V16Z"
                        stroke="#1D4ED8"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12 16H12.002V16.002H12V16Z"
                        stroke="#1D4ED8"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8 16H8.002V16.002H8V16Z"
                        stroke="#1D4ED8"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16 12H16.002V12.002H16V12Z"
                        stroke="#1D4ED8"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12 12H12.002V12.002H12V12Z"
                        stroke="#1D4ED8"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8 12H8.002V12.002H8V12Z"
                        stroke="#1D4ED8"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4 8H20"
                        stroke="#1D4ED8"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16 2V4"
                        stroke="#1D4ED8"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8 2V4"
                        stroke="#1D4ED8"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span>
                      {lecture?.lecture_date
                        ? moment(lecture?.lecture_date).format("ll")
                        : "Not set"}
                    </span>
                  </div>
                  <div className="time">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                        stroke="#1D4ED8"
                        strokeWidth="2"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12 7V12H17"
                        stroke="#1D4ED8"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span>
                      {lecture?.lecture_time
                        ? moment(lecture?.lecture_time, "HH:mm:ss").format(
                            "hh:mm A"
                          )
                        : "Not set"}
                    </span>
                  </div>
                </div>
                <div className="mgTp"></div>
                <label htmlFor="time">FACILITATOR IN CHARGE</label>
                <p className="faci">{lecture?.lecturer}</p>
                {lecture?.status === 2 && (
                  <>
                    {inClass ? (
                      <button className="leave" onClick={leaveLecture}>
                        {!isLeaveLecLoading ? (
                          "Leave Lecture"
                        ) : (
                          <LoadingOutlined />
                        )}
                      </button>
                    ) : (
                      <button onClick={joinLecture} className="join">
                        {!isJoinLecLoading ? (
                          "Join Lecture"
                        ) : (
                          <LoadingOutlined />
                        )}
                      </button>
                    )}
                  </>
                )}
              </div>
              <div className="scdMain">
                <div className="tpScMn">
                  <button
                    onClick={() => navigate(`/attendee/classroom/feed/${id}`)}
                    disabled={!inClass || lecture?.status !== 2}
                    className="classroom"
                  >
                    Go to Classroom
                  </button>
                </div>
                <div className="btScMn">
                  <h2>Answered Questions</h2>
                  <div className="lott">
                    <Lottie animationData={empty} />
                    <p>No entries yet</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewLecture;
