import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectAccessToken } from "../store/Auth";

interface PrivateRouteProps {}

const PrivateRoute: React.FunctionComponent<PrivateRouteProps> = () => {
  const accessToken: string = useSelector(selectAccessToken);
  const location = useLocation();
  return accessToken ? (
    <Outlet />
  ) : (
    <Navigate to="/attendee/signin" replace state={{ from: location }} />
  );
};

export default PrivateRoute;
