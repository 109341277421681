import AttendeeSidebar from "../../../components/AttendeeSidebar/AttendeeSidebar";
import { notification, Table } from "antd";
import type { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState, useCallback } from "react";
import Lottie from "lottie-react";
import empty from "../../../images/empty.json";
import { Skeleton } from "antd";
import axios from "axios";
import { selectAccessToken, selectRefreshToken } from "../../../store/Auth";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { login } from "../../../store/Auth";

interface AttendeeHistoryProps {}

interface DataType {
  lecture: {
    key: string;
    lecture_id: string;
    lecture_date: string;
    title: string;
    created_date: string;
    lecturer: string;
    status: string;
    id: string;
  };
}

const AttendeeHistory: React.FunctionComponent<AttendeeHistoryProps> = () => {
  const token = useSelector(selectAccessToken);
  const refreshToken = useSelector(selectRefreshToken);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isTableLoading, setIsTableLoading] = useState<boolean>(true);

  const refreshAccessToken = useCallback(() => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/users/refresh-token`,
      data: {
        refresh: refreshToken,
      },
    })
      .then((res) => {
        dispatch(login(res.data));
      })
      .catch((err) => {
        navigate("/attendee/signin");
      });
  }, [dispatch, navigate, refreshToken]);

  const columns: ColumnsType<DataType> = [
    {
      title: "LECTURE ID",
      dataIndex: "lecture",
      key: "lecture_id",
      render: (lecture) => lecture.lecture_id,
    },
    {
      title: "TITLE",
      dataIndex: "lecture",
      key: "title",
      render: (lecture) => lecture.title,
    },
    {
      title: "DATE",
      dataIndex: "lecture",
      key: "created_on",
      render: (lecture) => <>{moment(lecture.lecture_date).format("ll")}</>,
    },
    {
      title: "INVITE LINK",
      dataIndex: "lecture",
      key: "link",
      render: (lecture) =>
        lecture.invite_link ? (
          <a href={lecture.invite_link} rel="noreferrer" target="_blank">
            {lecture.invite_link}
          </a>
        ) : (
          "No invite link"
        ),
    },
    {
      title: "STATUS",
      dataIndex: "lecture",
      key: "status",
      render: (lecture) => (
        <span
          className={
            lecture.status === 2
              ? "published"
              : lecture.status === 3
              ? "unpublished"
              : ""
          }
        >
          {lecture.status === 2
            ? "Accepted"
            : lecture.status === 3
            ? "Expired"
            : ""}
        </span>
      ),
    },
    {
      title: "ACTION",
      dataIndex: "lecture",
      key: "action",
      render: (item, record) => (
        <button
          onClick={() => navigate(`/attendee/lecture/${record.lecture.id}`)}
          className="vDeets"
        >
          View Details
        </button>
      ),
    },
  ];

  const [tableData, setTableData] = useState<DataType[]>([]);

  useEffect(() => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/lectures/user-lecture-history`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setTableData(res.data.data);
        setIsTableLoading(false);
      })
      .catch((err) => {
        setIsTableLoading(false);
        if (err.response.status === 401) {
          refreshAccessToken();
          return;
        }
        notification.error({
          message: "Error fetching lectures",
          description: "Oops! there was an error fetching your lectures",
        });
      });
  }, [token, refreshAccessToken]);

  return (
    <div className="dashCont">
      <AttendeeSidebar />
      <div className="flexMain">
        <div className="lectureCont">
          <div className="topLec">
            <h2>History</h2>
          </div>
          <div className="tableCont">
            {isTableLoading ? (
              <Skeleton className="padSk" active paragraph={{ rows: 6 }} />
            ) : tableData.length > 0 ? (
              <Table
                columns={columns}
                dataSource={tableData}
                rowKey={(lecture) => lecture.lecture.id}
              />
            ) : (
              <div className="emp">
                <Lottie animationData={empty} />
                <p>No lecture history yet.</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttendeeHistory;
