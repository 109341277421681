import Header from "../../../../components/header/Header";
import bgGradient from "../../../../images/bgrg.png";
import { Input, notification } from "antd";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";

interface SignupFacilitatorProps {}

const SignupFacilitator: React.FunctionComponent<
  SignupFacilitatorProps
> = () => {
  const isMobile = window.innerWidth <= 768;
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const userType: number = 1;

  const [capLetterPass, setCapLetterPass] = useState<boolean>(false);
  const [numberPass, setNumberPass] = useState<boolean>(false);
  const [charPass, setCharPass] = useState<boolean>(false);
  const [countPass, setCountPass] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const openSuccessNotification = () => {
    notification.success({
      message: "Signup Successful",
      description: "Please verify your account",
    });
  };

  const openErrorNotification = (text: string) => {
    notification.error({
      message: "Error",
      description: text,
    });
  };

  const signup = (e: any) => {
    e.preventDefault();
    if (
      firstName &&
      lastName &&
      email &&
      password !== "" &&
      capLetterPass &&
      numberPass &&
      charPass &&
      countPass
    ) {
      setIsLoading(true);
      axios({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/users/`,
        data: {
          first_name: firstName,
          last_name: lastName,
          email: email,
          user_type: userType,
          password: password,
        },
      })
        .then((res) => {
          openSuccessNotification();
          setIsLoading(false);
          navigate(`/verify-account/${email}`);
        })
        .catch((err) => {
          openErrorNotification(err.response.data.message);
          setIsLoading(false);
        });
    }
  };

  const handlePaasword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  useEffect(() => {
    const capRegex = new RegExp("[A-Z]", "g");
    const numberRegex = new RegExp("[0-9]", "g");
    const charRegex = new RegExp("[^a-zA-Z0-9 ]", "g");
    if (password.match(capRegex)) {
      setCapLetterPass(true);
    } else {
      setCapLetterPass(false);
    }

    if (password.match(numberRegex)) {
      setNumberPass(true);
    } else {
      setNumberPass(false);
    }

    if (password.match(charRegex)) {
      setCharPass(true);
    } else {
      setCharPass(false);
    }

    if (password.length >= 8) {
      setCountPass(true);
    } else setCountPass(false);
  }, [password]);

  return (
    <div className="onbdMain">
      <Header />
      <div className="onbdCont">
        <img src={bgGradient} alt="" className="grd" />
        <div className="whtCont">
          <div className="tpWht">
            <h4>Sign up</h4>
            <Link to="/attendee/signup">
              <p>Not a Facilitator?</p>
            </Link>
          </div>
          <form>
            <input
              type="text"
              name="firstName"
              id="firstName"
              placeholder="First name"
              onChange={(e) => setFirstName(e.target.value)}
            />
            <input
              type="text"
              name="lastName"
              id="lastName"
              placeholder="Last name"
              onChange={(e) => setLastName(e.target.value)}
            />
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Email address"
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input.Password placeholder="Password" onChange={handlePaasword} />
            {password.length > 0 && (
              <div className="passwordCheck">
                <p className={capLetterPass ? "pass" : ""}>
                  {capLetterPass && (
                    <svg
                      width="21"
                      height="21"
                      viewBox="0 0 21 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.25 10.5014L8.96231 14.2137L16.3869 6.78906"
                        stroke="#0F766E"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}

                  <span>1 CAPS letter</span>
                </p>
                <p className={numberPass ? "pass" : ""}>
                  {numberPass && (
                    <svg
                      width="21"
                      height="21"
                      viewBox="0 0 21 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.25 10.5014L8.96231 14.2137L16.3869 6.78906"
                        stroke="#0F766E"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                  <span>1 number</span>
                </p>
                <p className={charPass ? "pass" : ""}>
                  {charPass && (
                    <svg
                      width="21"
                      height="21"
                      viewBox="0 0 21 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.25 10.5014L8.96231 14.2137L16.3869 6.78906"
                        stroke="#0F766E"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                  <span>1 character</span>
                </p>
                <p className={countPass ? "pass" : ""}>
                  {countPass && (
                    <svg
                      width="21"
                      height="21"
                      viewBox="0 0 21 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.25 10.5014L8.96231 14.2137L16.3869 6.78906"
                        stroke="#0F766E"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                  <span>Min. 8 letters</span>
                </p>
              </div>
            )}
            <p className="ctnc">
              By signing up, you agree to the{" "}
              <span className="tc">Terms and Conditions</span>{" "}
              {!isMobile && <br />} and{" "}
              <span className="tc">Privacy Policy</span>.{" "}
            </p>
            <button onClick={signup}>
              {!isLoading ? "Agree and Sign up" : <LoadingOutlined />}
            </button>
          </form>
          <p className="renD">
            Already have an account?{" "}
            <Link to={"/facilitator/signin"}>Log in</Link>
          </p>
          <div className="tnc">
            <p>Terms and Condtions</p>
            <span>.</span>
            <p>Privacy Policy</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupFacilitator;
