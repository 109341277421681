import "./index.scss";
import { DatePickerProps, notification } from "antd";
import { DatePicker, TimePicker } from "antd";
import React, { useEffect, useState } from "react";
import { selectAccessToken, selectRefreshToken } from "../../store/Auth";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import type { RangePickerProps } from "antd/es/date-picker";
import { login } from "../../store/Auth";
interface NewLectureProps {
  setLecturesArray?: any;
  setIsModalVisible?: any;
  setIsSuccess?: any;
  setTableData?: any;
  setSuccessLectureID?: any;
}

const NewLecture: React.FunctionComponent<NewLectureProps> = ({
  setLecturesArray,
  setIsModalVisible,
  setIsSuccess,
  setTableData,
  setSuccessLectureID,
}) => {
  const [lectureTitle, setLectureTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [keywordInput, setKeywordInput] = useState<string>("");
  const [date, setDate] = useState<string>("");

  const [lecError, setLecError] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPublishLoading, setIsPublishLoading] = useState<boolean>(false);
  const [isPublishDisabled, setIsPublishDisabled] = useState<boolean>(true);

  const onDateChange: DatePickerProps["onChange"] = (date, dateString) => {
    setDate(dateString);
  };

  const [selectedTime, setSelectedTime] = useState("");

  const [keywords, setKeywords] = useState<{ keyword: string; id: string }[]>(
    []
  );
  const token = useSelector(selectAccessToken);
  const refreshToken = useSelector(selectRefreshToken);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days before today and today
    return current && current < moment().endOf("day");
  };

  const resetForm = () => {
    setLectureTitle("");
    setDescription("");
    setDate("");
    setSelectedTime("");
    setKeywordInput("");
    setKeywords([]);
  };

  const refreshAccessToken = () => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/users/refresh-token`,
      data: {
        refresh: refreshToken,
      },
    })
      .then((res) => {
        dispatch(login(res.data));
      })
      .catch((err) => {
        navigate("/attendee/signin");
      });
  };

  const saveDraft = () => {
    if (lectureTitle !== "") {
      setLecError(false);
      setIsLoading(true);
      axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/lectures/`,
        data: {
          title: lectureTitle,
          status: 1,
          description: description,
          lecture_keys: keywords,
          lecture_date: date === "" ? null : date,
          lecture_time: selectedTime === "" ? null : selectedTime,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          fetchLectures();
          setIsLoading(false);
          setIsModalVisible(false);
          setSuccessLectureID(res.data.data.id);
          setIsSuccess(true);
          resetForm();
        })
        .catch((err) => {
          setIsLoading(false);
          if (err.response.status === 401) {
            refreshAccessToken();
            return;
          }
          notification.error({
            message: "Error saving lecture draft",
            description: "Oops! there was an error saving your lecture",
          });
        });
    } else {
      setLecError(true);
    }
  };

  useEffect(() => {
    if (lectureTitle !== "" && keywords.length > 0) {
      setIsPublishDisabled(false);
    } else {
      setIsPublishDisabled(true);
    }
  }, [lectureTitle, date, selectedTime, keywords]);

  const addKeyword = (e: any) => {
    e.preventDefault();
    if (keywordInput.length > 0) {
      let newKeyword = keywordInput.split(",");
      let newArr: { keyword: string; id: string }[] = [];
      newKeyword.map((k, idx) => {
        newArr.push({ keyword: k, id: `${idx} ${new Date()}` });
        return newArr;
      });
      setKeywords([...keywords, ...newArr]);
      setKeywordInput("");
    }
  };

  const deleteKeyword = (id: string) => {
    const filteredArray = keywords.filter((keyword) => keyword.id !== id);
    setKeywords(filteredArray);
  };

  const publishLecture = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (lectureTitle !== "" && keywords.length > 0) {
      setIsPublishLoading(true);
      axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/lectures/`,
        data: {
          title: lectureTitle,
          status: 2,
          description: description,
          lecture_keys: keywords,
          lecture_date: date === "" ? null : date,
          lecture_time: selectedTime === "" ? null : selectedTime,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          fetchLectures();
          setIsPublishLoading(false);
          setIsModalVisible(false);
          setSuccessLectureID(res.data.data.id);
          setIsSuccess(true);
          resetForm();
        })
        .catch((err) => {
          setIsPublishLoading(false);
          if (err.response.status === 401) {
            refreshAccessToken();
            return;
          }
          notification.error({
            message: "Error publishing lecture",
            description: "Oops! there was an error publishing your lecture",
          });
        });
    }
  };

  const fetchLectures = () => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/lectures/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        pathname === "/facilitator/dashboard"
          ? setLecturesArray(res.data.data)
          : pathname === "/facilitator/lectures"
          ? setTableData(res.data.data)
          : setLecturesArray(res.data.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          refreshAccessToken();
          return;
        }
      });
  };

  const KeywordTag = ({ keyword, id }: { keyword: string; id: string }) => {
    return (
      <div className="tagWrp">
        <svg
          onClick={() => deleteKeyword(id)}
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 16L8 8"
            stroke="#0F766E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16 8L8 16"
            stroke="#0F766E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <span>{keyword}</span>
      </div>
    );
  };

  return (
    <div className="modCont">
      <div className="tpMod">
        <h2>New Lecture</h2>
        <button onClick={saveDraft}>
          {!isLoading ? "Save Draft" : <LoadingOutlined />}
        </button>
      </div>
      <form
        onSubmit={(e: React.FormEvent<HTMLFormElement>) => publishLecture(e)}
      >
        <input
          type="text"
          name="title"
          id="title"
          placeholder="Lecture Title"
          value={lectureTitle}
          onChange={(e) => {
            setLectureTitle(e.target.value);
            if (e.target.value !== "") {
              setLecError(false);
            }
          }}
        />
        {lecError && (
          <p className="lecErr">
            Enter at least a “Title and date” to Save as Draft
          </p>
        )}
        <textarea
          name="desciption"
          id="description"
          cols={30}
          rows={7}
          placeholder="Description (Optional)"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        ></textarea>
        <div className="flx">
          <DatePicker
            onChange={onDateChange}
            placeholder={"Select Lecture Date"}
            value={date !== "" ? moment(date) : null}
            disabledDate={disabledDate}
          />
          <TimePicker
            onSelect={(value) => {
              const timeString = moment(value).format("HH:mm");
              setSelectedTime(timeString);
            }}
            placeholder={"Select Lecture Time"}
            format={"HH:mm"}
            showNow={false}
            value={selectedTime !== "" ? moment(selectedTime, "HH:mm") : null}
          />
        </div>
        <div className="flx">
          <input
            type="text"
            name="keywords"
            id="keywords"
            placeholder="Enter keywords seperated by a comma"
            onChange={(e) => setKeywordInput(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && addKeyword(e)}
            value={keywordInput}
            className="keywords"
          />
          <button onClick={addKeyword} className="addKey">
            Add
          </button>
        </div>
        <div className="kwdCont">
          {keywords.map((keyword) => (
            <KeywordTag
              key={keyword.id}
              keyword={keyword.keyword}
              id={keyword.id}
            />
          ))}
        </div>
        <p>
          Keywords are used to rank the most relevant questions being asked. You
          can enter as many keywords as you need.
        </p>
        <div className="flx">
          <button disabled={isPublishDisabled} className="publish">
            {!isPublishLoading ? "Publish Lecture" : <LoadingOutlined />}
          </button>
          <button onClick={() => setIsModalVisible(false)} className="cancel">
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default NewLecture;
