import { Link } from "react-router-dom";
import About from "../../../components/about/About";
import Contact from "../../../components/contact/Contact";
import Footer from "../../../components/footer/Footer";
import Header from "../../../components/header/Header";
import dashboardImg from "../../../images/heroimg.png";
import "./landingpage.scss";
interface LandingPageProps {}

const LandingPage: React.FunctionComponent<LandingPageProps> = () => {
  const isMobile = window.innerWidth <= 768;
  return (
    <div>
      <Header />
      <div className="hero">
        <h1>
          Get through questions faster {!isMobile && <br />} with Rankaque
        </h1>
        <p>
          A platform that allows Attendees to express questions anonymously,
          while also helping {!isMobile && <br />} the Facilitators get through
          the most relevant questions faster.
        </p>
        <div className="flxBtns">
          <Link to="/facilitator/signup">
            <button className="faci">I am a Facilitator</button>
          </Link>
          <Link to="/attendee/signup">
            <button className="att">I am an Attendee</button>
          </Link>
        </div>
        <img data-aos="fade-up" src={dashboardImg} alt="" />
      </div>
      <About />
      <Contact />
      <Footer />
    </div>
  );
};

export default LandingPage;
