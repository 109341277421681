import FacilitatorSidebar from "../../../components/FacilitatorSidebar/FacilitatorSidebar";
import { useNavigate } from "react-router-dom";
import { Tabs, Upload, Button } from "antd";
import avatar from "../../../images/defaultPic.png";
import { UploadOutlined } from "@ant-design/icons";
import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { selectAccessToken, selectRefreshToken } from "../../../store/Auth";
import { setUser, selectUser } from "../../../store/User";
import { useSelector, useDispatch } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import { notification } from "antd";
import type { UploadProps } from "antd";
import { login } from "../../../store/Auth";

interface FacilitatorEditProfileProps {}

const FacilitatorEditProfile: React.FunctionComponent<
  FacilitatorEditProfileProps
> = () => {
  interface UserDetails {
    first_name: string;
    last_name: string;
    phone_number: null | string;
    email: string;
    profile: {
      certificates: null;
      documents: null;
      occupation: string;
      years_of_experience: string;
      picture: File;
    };
  }

  const navigate = useNavigate();
  const { TabPane } = Tabs;
  const token = useSelector(selectAccessToken);
  const refreshToken = useSelector(selectRefreshToken);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const [userDetails, setUserDetails] = useState<UserDetails>();

  const [firstname, setFirstname] = useState<string>("");
  const [lastname, setlastname] = useState<string>("");
  const [email, setemail] = useState<string>("");
  const [yearsOfExperience, setYearsOfExperience] = useState<string>("");
  const [occupation, setOccupation] = useState<string>("");
  const [file, setFile] = useState<any>();
  const [isPLoading, setIsPLoading] = useState<boolean>(false);

  const refreshAccessToken = useCallback(() => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/users/refresh-token`,
      data: {
        refresh: refreshToken,
      },
    })
      .then((res) => {
        dispatch(login(res.data));
      })
      .catch((err) => {
        navigate("/attendee/signin");
      });
  }, [dispatch, navigate, refreshToken]);

  useEffect(() => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/users/user-detail`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setUserDetails(res.data.data);
        setFirstname(res.data.data.first_name);
        setlastname(res.data.data.last_name);
        setemail(res.data.data.email);
        setOccupation(res.data.data.profile.occupation);
        setYearsOfExperience(res.data.data.profile.years_of_experience);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          refreshAccessToken();
          return;
        }
      });
  }, [token, refreshAccessToken]);

  const savePersonalChanges = () => {
    setIsPLoading(true);
    axios({
      method: "put",
      url: `${process.env.REACT_APP_BASE_URL}/users/update-profile`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        first_name: firstname,
        last_name: lastname,
        phone_number: null,
        email: email,
        profile: {
          picture: file ? file : null,
          certificates: null,
          documents: null,
          occupation: occupation,
          years_of_experience: yearsOfExperience,
        },
      },
    })
      .then((res) => {
        dispatch(setUser(res.data.data));
        setIsPLoading(false);
        notification.success({
          message: "Profile updated",
          description: "Your profile has been updated successfully",
        });
      })
      .catch((err) => {
        setIsPLoading(false);
        if (err.response.status === 401) {
          refreshAccessToken();
          return;
        }
        notification.error({
          message: "Error updating profile",
          description: "Oops! there was an error updating your profile",
        });
      });
  };

  const props: UploadProps = {
    name: "picture",
    beforeUpload: () => false,
    method: "PUT",
    onChange(info) {
      const handleFile = async () => {
        if (info.file) {
          const data: any = await new Promise((resolve, reject) => {
            const reader: any = new FileReader();
            reader.readAsDataURL(info.file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error: any) => reject(error);
          });
          setFile(data);
        }
      };
      handleFile();
    },
  };

  return (
    <div className="dashCont">
      <FacilitatorSidebar />
      <div className="flexMain">
        <div className="editCont">
          <div
            className="goback"
            onClick={() => navigate("/facilitator/profile")}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11V13ZM5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13L5 11ZM19 11L5 11L5 13L19 13V11Z"
                fill="#34363B"
              />
              <path
                d="M10.2929 18.7071C10.6834 19.0976 11.3166 19.0976 11.7071 18.7071C12.0976 18.3166 12.0976 17.6834 11.7071 17.2929L10.2929 18.7071ZM5 12L4.29289 11.2929C4.10536 11.4804 4 11.7348 4 12C4 12.2652 4.10536 12.5196 4.29289 12.7071L5 12ZM11.7071 6.70711C12.0976 6.31658 12.0976 5.68342 11.7071 5.29289C11.3166 4.90237 10.6834 4.90237 10.2929 5.29289L11.7071 6.70711ZM11.7071 17.2929L5.70711 11.2929L4.29289 12.7071L10.2929 18.7071L11.7071 17.2929ZM5.70711 12.7071L11.7071 6.70711L10.2929 5.29289L4.29289 11.2929L5.70711 12.7071Z"
                fill="#34363B"
              />
            </svg>
            <h3>Edit Profile</h3>
          </div>
          <div className="fwdth">
            <div className="edWht">
              <Tabs defaultActiveKey="1">
                <TabPane tab="Personal" key="1">
                  <div className="flxImg">
                    <img
                      src={
                        user.profile.picture !== null
                          ? user.profile.picture
                          : avatar
                      }
                      alt=""
                      className="profilePic"
                    />
                    <Upload {...props}>
                      <Button className="upload" icon={<UploadOutlined />}>
                        Upload an image
                      </Button>
                    </Upload>
                  </div>
                  <div className="formField">
                    <label htmlFor="firstName">FIRST NAME</label>
                    <input
                      type="text"
                      name="firstname"
                      id="firstname"
                      defaultValue={userDetails?.first_name}
                      onChange={(e) => setFirstname(e.target.value)}
                    />
                  </div>
                  <div className="formField">
                    <label htmlFor="lastname">LAST NAME</label>
                    <input
                      type="text"
                      name="lastname"
                      id="lastname"
                      defaultValue={userDetails?.last_name}
                      onChange={(e) => setlastname(e.target.value)}
                    />
                  </div>
                  <div className="formField">
                    <label htmlFor="email">EMAIL</label>
                    <input
                      type="text"
                      name="email"
                      id="email"
                      defaultValue={userDetails?.email}
                      onChange={(e) => setemail(e.target.value)}
                    />
                  </div>
                  <button onClick={savePersonalChanges} className="save">
                    {!isPLoading ? "Save Changes" : <LoadingOutlined />}
                  </button>
                </TabPane>
                <TabPane tab="Professional" key="2">
                  <div className="formField">
                    <label htmlFor="occupation">OCCUPATION</label>
                    <input
                      type="text"
                      name="occupation"
                      id="occupation"
                      defaultValue={userDetails?.profile?.occupation}
                      onChange={(e) => setOccupation(e.target.value)}
                    />
                  </div>
                  <div className="formField">
                    <label htmlFor="yoe">YEARS OF EXPERIENCE</label>
                    <input
                      type="text"
                      name="yoe"
                      id="yoe"
                      defaultValue={userDetails?.profile?.years_of_experience}
                      onChange={(e) => setYearsOfExperience(e.target.value)}
                    />
                  </div>
                  <div className="formField">
                    <label htmlFor="CERT">Certifications/ Licenses</label>
                    <input type="text" name="CERT" id="CERT" />
                  </div>
                  <button onClick={savePersonalChanges} className="save">
                    {!isPLoading ? "Save Changes" : <LoadingOutlined />}
                  </button>
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FacilitatorEditProfile;
