import "./index.scss";
import profileImg from "../../images/defaultPic.png";
import { Input, notification } from "antd";
import { selectUser } from "../../store/User";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SearchCard from "../searchCard/SearchCard";
import { useCallback, useState } from "react";
import axios from "axios";
import { login, selectAccessToken, selectRefreshToken } from "../../store/Auth";
import moment from "moment";
import Lottie from "lottie-react";
import empty from "../../images/empty.json";

interface AttendeeTopbarProps {}

const AttendeeTopbar: React.FunctionComponent<AttendeeTopbarProps> = () => {
  interface User {
    email: string;
    first_name: string;
    last_name: string;
    phone_number: number;
    profile: {
      picture: string;
    };
  }

  type SearchResult = {
    id: number;
    lecture_date: string;
    lecture_time: string;
    title: string;
  };

  const [openSearch, setOpenSearch] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string>("");
  const user: User = useSelector(selectUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const refreshToken = useSelector(selectRefreshToken);
  const token = useSelector(selectAccessToken);
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);

  const refreshAccessToken = useCallback(() => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/users/refresh-token`,
      data: {
        refresh: refreshToken,
      },
    })
      .then((res) => {
        dispatch(login(res.data));
      })
      .catch((err) => {
        navigate("/attendee/signin");
      });
  }, [dispatch, navigate, refreshToken]);

  const searchLecture = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOpenSearch(true);
    setSearchString(e.target.value);
    axios({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/lectures/search-lecture?search=${e.target.value}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setSearchResults(res.data.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          refreshAccessToken();
          return;
        }
        notification.error({
          message: "Error fetching lectures",
          description: "Oops! there was an error fetching lectures",
        });
      });
  };

  return (
    <div className="atHeader">
      <div className="leftTb">
        <h1>Welcome, {user.first_name}</h1>
        <button onClick={() => navigate("/attendee/edit-profile")}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 20H20"
              stroke="#3E4047"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8 19.9998V20.9998C8.26522 20.9998 8.51957 20.8945 8.70711 20.707L8 19.9998ZM4 19.9998H3C3 20.5521 3.44772 20.9998 4 20.9998V19.9998ZM4 15.9998L3.29289 15.2927C3.10536 15.4803 3 15.7346 3 15.9998H4ZM15.2929 4.70696L16 5.41406L16 5.41406L15.2929 4.70696ZM16.7071 4.70696L16 5.41406L16 5.41406L16.7071 4.70696ZM19.2929 7.29274L20 6.58564V6.58564L19.2929 7.29274ZM19.2929 8.70696L18.5858 7.99985L19.2929 8.70696ZM8 18.9998H4V20.9998H8V18.9998ZM5 19.9998V15.9998H3V19.9998H5ZM4.70711 16.707L16 5.41406L14.5858 3.99985L3.29289 15.2927L4.70711 16.707ZM16 5.41406L18.5858 7.99985L20 6.58564L17.4142 3.99985L16 5.41406ZM18.5858 7.99985L7.29289 19.2927L8.70711 20.707L20 9.41406L18.5858 7.99985ZM18.5858 7.99985V7.99985L20 9.41406C20.781 8.63302 20.781 7.36669 20 6.58564L18.5858 7.99985ZM16 5.41406H16L17.4142 3.99985C16.6332 3.2188 15.3668 3.2188 14.5858 3.99985L16 5.41406Z"
              fill="#3E4047"
            />
          </svg>
        </button>
      </div>
      <div className="rghtTb">
        <Input
          type="search"
          name="search"
          id="search"
          placeholder="Search for Lectures..."
          onChange={(e) => searchLecture(e)}
          prefix={
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17Z"
                stroke="#4A4E57"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M15 15L21 21"
                stroke="#4A4E57"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          }
        />
        {openSearch && searchString ? (
          <div className="searchResults">
            {searchResults.length > 0 ? (
              <>
                {searchResults.map((result) => (
                  <SearchCard
                    id={result.id}
                    date={
                      result.lecture_date
                        ? moment(result.lecture_date).format("ll")
                        : "Not set"
                    }
                    time={
                      result.lecture_time
                        ? moment(result.lecture_time, "HH:mm:ss").format(
                            "hh:mm A"
                          )
                        : "Not set"
                    }
                    setOpenSearch={setOpenSearch}
                    title={result.title}
                    key={result.id}
                  />
                ))}
              </>
            ) : (
              <div className="lott">
                <Lottie animationData={empty} />
                <p>No search result</p>
              </div>
            )}
          </div>
        ) : (
          <></>
        )}
        <button>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 17V16C8.44772 16 8 16.4477 8 17H9ZM15 17H16C16 16.4477 15.5523 16 15 16V17ZM9 18H15V16H9V18ZM14 17V18H16V17H14ZM10 18V17H8V18H10ZM12 20C10.8954 20 10 19.1046 10 18H8C8 20.2091 9.79086 22 12 22V20ZM14 18C14 19.1046 13.1046 20 12 20V22C14.2091 22 16 20.2091 16 18H14Z"
              fill="#3E4047"
            />
            <path
              d="M4.29289 14.7071L3.58579 14L3.58579 14L4.29289 14.7071ZM4.80372 14.1963L5.51082 14.9034L5.51082 14.9034L4.80372 14.1963ZM19.1963 14.1963L19.9034 13.4892L19.9034 13.4892L19.1963 14.1963ZM19.7071 14.7071L20.4142 14L20.4142 14L19.7071 14.7071ZM12 3L12 4L12 3ZM19 16H5V18H19V16ZM5 16V15.4142H3V16H5ZM5 15.4142L5.51082 14.9034L4.09661 13.4892L3.58579 14L5 15.4142ZM6 13.7224V10H4V13.7224H6ZM18 10V13.7224H20V10H18ZM18.4892 14.9034L19 15.4142L20.4142 14L19.9034 13.4892L18.4892 14.9034ZM19 15.4142V16H21V15.4142H19ZM19 15.4142L19 15.4142H21C21 14.8838 20.7893 14.3751 20.4142 14L19 15.4142ZM18 13.7224C18 14.1653 18.176 14.5901 18.4892 14.9034L19.9034 13.4892C19.9653 13.551 20 13.6349 20 13.7224H18ZM20 10C20 5.58171 16.4183 1.99999 12 2L12 4C15.3137 3.99999 18 6.68629 18 10H20ZM6 10C6 6.6863 8.68628 4.00001 12 4L12 2C7.58171 2.00001 4 5.58173 4 10H6ZM5.51082 14.9034C5.82404 14.5902 6 14.1654 6 13.7224H4C4 13.635 4.03475 13.5511 4.09661 13.4892L5.51082 14.9034ZM5 15.4142H5L3.58579 14C3.21071 14.3751 3 14.8838 3 15.4142H5ZM5 16H3C3 17.1046 3.89543 18 5 18V16ZM19 18C20.1046 18 21 17.1046 21 16H19V18Z"
              fill="#3E4047"
            />
          </svg>
        </button>
        <img
          src={
            user?.profile?.picture !== null
              ? user?.profile?.picture
              : profileImg
          }
          alt=""
        />
      </div>
    </div>
  );
};

export default AttendeeTopbar;
