import Header from "../../../../components/header/Header";
import bgGradient from "../../../../images/bgrg.png";
import { Input } from "antd";
import { useEffect, useState } from "react";
import axios from "axios";
import { LoadingOutlined } from "@ant-design/icons";
import { notification } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

interface ResetFacilitatorPasswordProps {}

const ResetFacilitatorPassword: React.FunctionComponent<
  ResetFacilitatorPasswordProps
> = () => {
  const [capLetterPass, setCapLetterPass] = useState<boolean>(false);
  const [numberPass, setNumberPass] = useState<boolean>(false);
  const [charPass, setCharPass] = useState<boolean>(false);
  const [countPass, setCountPass] = useState<boolean>(false);
  const [matchPass, setmatchPass] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [confirmpassword, setconfirmPassword] = useState<string>("");

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();
  const queryString = new URLSearchParams(location.search);

  const email = queryString.get("id");
  const otp = queryString.get("otp");

  const openSuccessNotification = () => {
    notification.success({
      message: "Reset successful",
      description: "Your password has been reset successfully, kindly login",
    });
  };

  const openErrorNotification = (text: string) => {
    notification.error({
      message: "Error",
      description: text,
    });
  };

  useEffect(() => {
    const capRegex = new RegExp("[A-Z]", "g");
    const numberRegex = new RegExp("[0-9]", "g");
    const charRegex = new RegExp("[^a-zA-Z0-9 ]", "g");
    if (password.match(capRegex)) {
      setCapLetterPass(true);
    } else {
      setCapLetterPass(false);
    }

    if (password.match(numberRegex)) {
      setNumberPass(true);
    } else {
      setNumberPass(false);
    }

    if (password.match(charRegex)) {
      setCharPass(true);
    } else {
      setCharPass(false);
    }

    if (password.length >= 8) {
      setCountPass(true);
    } else setCountPass(false);
  }, [password]);

  useEffect(() => {
    if (password === confirmpassword) {
      setmatchPass(true);
    } else setmatchPass(false);
  }, [password, confirmpassword]);

  const resetPassword = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (capLetterPass && numberPass && charPass && countPass && matchPass) {
      setIsLoading(true);
      axios({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/users/reset-password`,
        data: {
          email: email,
          otp: otp,
          password: password,
        },
      })
        .then((res) => {
          openSuccessNotification();
          setIsLoading(false);
          navigate("/reset-password/success");
        })
        .catch((err) => {
          openErrorNotification(err.response.data.message);
          setIsLoading(false);
        });
    }
  };

  return (
    <div className="onbdMain">
      <Header />
      <div className="onbdCont">
        <img src={bgGradient} alt="" className="grd" />
        <div className="whtCont">
          <div className="tpWht">
            <h4>Reset Password</h4>
          </div>
          <p>Create a new password</p>
          <form onSubmit={(e) => resetPassword(e)}>
            <Input.Password
              placeholder="New password"
              onChange={(e) => setPassword(e.target.value)}
            />
            {password.length > 0 && (
              <div className="passwordCheck">
                <p className={capLetterPass ? "pass" : ""}>
                  {capLetterPass && (
                    <svg
                      width="21"
                      height="21"
                      viewBox="0 0 21 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.25 10.5014L8.96231 14.2137L16.3869 6.78906"
                        stroke="#0F766E"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}

                  <span>1 CAPS letter</span>
                </p>
                <p className={numberPass ? "pass" : ""}>
                  {numberPass && (
                    <svg
                      width="21"
                      height="21"
                      viewBox="0 0 21 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.25 10.5014L8.96231 14.2137L16.3869 6.78906"
                        stroke="#0F766E"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                  <span>1 number</span>
                </p>
                <p className={charPass ? "pass" : ""}>
                  {charPass && (
                    <svg
                      width="21"
                      height="21"
                      viewBox="0 0 21 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.25 10.5014L8.96231 14.2137L16.3869 6.78906"
                        stroke="#0F766E"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                  <span>1 character</span>
                </p>
                <p className={countPass ? "pass" : ""}>
                  {countPass && (
                    <svg
                      width="21"
                      height="21"
                      viewBox="0 0 21 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.25 10.5014L8.96231 14.2137L16.3869 6.78906"
                        stroke="#0F766E"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                  <span>Min. 8 letters</span>
                </p>
              </div>
            )}
            <Input.Password
              placeholder="Re-enter new password"
              onChange={(e) => setconfirmPassword(e.target.value)}
            />
            {confirmpassword.length > 0 && (
              <div className="passwordCheck">
                <p className={matchPass ? "pass" : ""}>
                  {matchPass && (
                    <svg
                      width="21"
                      height="21"
                      viewBox="0 0 21 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.25 10.5014L8.96231 14.2137L16.3869 6.78906"
                        stroke="#0F766E"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                  <span>Matching entry</span>
                </p>
              </div>
            )}
            <button>{!isLoading ? "Reset" : <LoadingOutlined />}</button>
          </form>
          <div className="tnc">
            <p>Terms and Condtions</p>
            <span>.</span>
            <p>Privacy Policy</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetFacilitatorPassword;
