import { useNavigate } from "react-router-dom";
import "./index.scss";

interface SearchCardProps {
  title: string;
  date: string;
  time: string;
  id: number;
  setOpenSearch: React.Dispatch<React.SetStateAction<boolean>>;
}

const SearchCard: React.FunctionComponent<SearchCardProps> = ({
  title,
  date,
  time,
  id,
  setOpenSearch,
}) => {
  const navigate = useNavigate();

  const handleClick = (id: number) => {
    navigate(`/attendee/lecture/${id}`);
    setOpenSearch(false);
  };

  return (
    <div className="searchCard" onClick={() => handleClick(id)}>
      <div className="bdBlue"></div>
      <p className="title">{title}</p>
      <div className="flexSCard">
        <div className="date">
          <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.625 3.5H4.375C3.89175 3.5 3.5 3.89175 3.5 4.375V16.625C3.5 17.1082 3.89175 17.5 4.375 17.5H16.625C17.1082 17.5 17.5 17.1082 17.5 16.625V4.375C17.5 3.89175 17.1082 3.5 16.625 3.5Z"
              stroke="#1D4ED8"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14 14H14.0017V14.0017H14V14Z"
              stroke="#1D4ED8"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10.5 14H10.5017V14.0017H10.5V14Z"
              stroke="#1D4ED8"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7 14H7.00175V14.0017H7V14Z"
              stroke="#1D4ED8"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14 10.5H14.0017V10.5017H14V10.5Z"
              stroke="#1D4ED8"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10.5 10.5H10.5017V10.5017H10.5V10.5Z"
              stroke="#1D4ED8"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7 10.5H7.00175V10.5017H7V10.5Z"
              stroke="#1D4ED8"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3.5 7H17.5"
              stroke="#1D4ED8"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14 1.75V3.5"
              stroke="#1D4ED8"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7 1.75V3.5"
              stroke="#1D4ED8"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span>{date}</span>
        </div>
        <div className="time">
          <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.5 18.375C14.8492 18.375 18.375 14.8492 18.375 10.5C18.375 6.15076 14.8492 2.625 10.5 2.625C6.15076 2.625 2.625 6.15076 2.625 10.5C2.625 14.8492 6.15076 18.375 10.5 18.375Z"
              stroke="#1D4ED8"
              strokeWidth="2"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10.5 6.125V10.5H14.875"
              stroke="#1D4ED8"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span>{time}</span>
        </div>
      </div>
    </div>
  );
};

export default SearchCard;
