import Header from "../../../../components/header/Header";
import bgGradient from "../../../../images/bgrg.png";
import success from "../../../../images/success.json";
import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom";

interface SuccessProps {}

const Success: React.FunctionComponent<SuccessProps> = () => {
  const navigate = useNavigate();

  const handleClick = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    navigate("/facilitator/signin");
  };
  return (
    <div className="onbdMain">
      <Header />
      <div className="onbdCont">
        <img src={bgGradient} alt="" className="grd" />
        <div className="whtCont">
          <h4>Account verified</h4>
          <div className="lott">
            <Lottie animationData={success} />
          </div>
          <h3>Your account is now verified</h3>
          <p className="ver">Proceed to your Rankaque account.</p>
          <form onSubmit={(e) => handleClick(e)}>
            <button>Continue</button>
          </form>
          <div className="tnc">
            <p>Terms and Condtions</p>
            <span>.</span>
            <p>Privacy Policy</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Success;
