import FacilitatorSidebar from "../../../components/FacilitatorSidebar/FacilitatorSidebar";
import Lottie from "lottie-react";
import empty from "../../../images/empty.json";
import { notification, Skeleton, Table } from "antd";
import type { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { selectAccessToken, selectRefreshToken } from "../../../store/Auth";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { login } from "../../../store/Auth";
import { useNavigate } from "react-router-dom";

interface FacilitatorInvitesProps {}

interface DataType {
  key: string;
  lecture_id: string;
  title: string;
  created_date: string;
  lecturer: string;
  status: number;
  id: number;
}

const FacilitatorInvites: React.FunctionComponent<
  FacilitatorInvitesProps
> = () => {
  const token = useSelector(selectAccessToken);
  const refreshToken = useSelector(selectRefreshToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const refreshAccessToken = useCallback(() => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/users/refresh-token`,
      data: {
        refresh: refreshToken,
      },
    })
      .then((res) => {
        dispatch(login(res.data));
      })
      .catch((err) => {
        navigate("/attendee/signin");
      });
  }, [dispatch, navigate, refreshToken]);

  const columns: ColumnsType<DataType> = [
    {
      title: "LECTURE ID",
      dataIndex: "lecture_id",
      key: "lecture_id",
    },
    {
      title: "TITLE",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "DATE CREATED",
      dataIndex: "created_at",
      key: "created_at",
      render: (item) => <>{moment(item).format("ll")}</>,
    },
    {
      title: "INVITE LINK URL",
      dataIndex: "invite_link",
      key: "invite_link",
      render: (link) =>
        link ? (
          <a href={link} rel="noreferrer" target="_blank">
            {link}
          </a>
        ) : (
          "No invite link"
        ),
    },
    {
      title: "INVITE ACCEPTED BY",
      dataIndex: "number_of_attendees",
      key: "number_of_attendees",
      render: (number) => (
        <>
          <span className="numAtt">({number})</span> Attendees
        </>
      ),
    },
    {
      title: "ACTION",
      dataIndex: "action",
      key: "action",
      render: (item = "Deactivate Link", record) => (
        <button
          disabled={record.status !== 2 && true}
          onClick={() => deactivateLink(record.id)}
          className="deactivate"
        >
          Deactivate Link
        </button>
      ),
    },
  ];

  const [tableData, setTableData] = useState<DataType[]>([]);
  const [isTableLoading, setIsTableLoading] = useState<boolean>(true);

  const fetchLectures = () => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/lectures/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setTableData(res.data.data);
        setIsTableLoading(false);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          refreshAccessToken();
          return;
        }
        notification.error({
          message: "Error fetching lecture invites",
          description: "Oops! there was an error fetching your lecture invites",
        });
      });
  };

  useEffect(() => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/lectures/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setTableData(res.data.data);
        setIsTableLoading(false);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          refreshAccessToken();
          return;
        }
        notification.error({
          message: "Error fetching lecture invites",
          description: "Oops! there was an error fetching your lecture invites",
        });
      });
  }, [token, refreshAccessToken]);

  const deactivateLink = (id: number) => {
    axios({
      method: "put",
      url: `${process.env.REACT_APP_BASE_URL}/lectures/${id}`,
      data: {
        status: 3,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        fetchLectures();
        notification.success({
          message: "Invite link Deactivated",
          description: "Your invite link has been deactivated successfully",
        });
      })
      .catch((err) => {
        if (err.response.status === 401) {
          refreshAccessToken();
          return;
        }
        notification.error({
          message: "Error deactivating invite link",
          description: "Oops! there was an error deactivating your invite link",
        });
      });
  };

  return (
    <div className="dashCont">
      <FacilitatorSidebar />
      <div className="flexMain">
        <div className="inviteCont lectureCont">
          <h2>Invites</h2>
          <div className="tableCont">
            {isTableLoading ? (
              <Skeleton className="padSk" active paragraph={{ rows: 6 }} />
            ) : tableData.length > 0 ? (
              <Table
                columns={columns}
                dataSource={tableData}
                rowKey={"lecture_id"}
              />
            ) : (
              <div className="emp">
                <Lottie animationData={empty} />
                <p>No invites generated yet.</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FacilitatorInvites;
