import Header from "../../../../components/header/Header";
import bgGradient from "../../../../images/bgrg.png";
import axios from "axios";
import { LoadingOutlined } from "@ant-design/icons";
import { notification } from "antd";
import { useState } from "react";

interface ForgotFacilitatorPasswordProps {}

const ForgotFacilitatorPassword: React.FunctionComponent<
  ForgotFacilitatorPasswordProps
> = () => {
  const [email, setEmail] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const openSuccessNotification = () => {
    notification.success({
      message: "Email sent",
      description: "A link to reset your password has been sent to your email",
    });
  };

  const openErrorNotification = (text: string) => {
    notification.error({
      message: "Error",
      description: text,
    });
  };

  const forgotPassword = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (email !== "") {
      setIsLoading(true);
      axios({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/users/send-reset-link`,
        data: {
          email: email,
        },
      })
        .then((res) => {
          openSuccessNotification();
          setIsLoading(false);
        })
        .catch((err) => {
          openErrorNotification(err.response.data.message);
          setIsLoading(false);
        });
    }
  };

  return (
    <div className="onbdMain">
      <Header />
      <div className="onbdCont">
        <img src={bgGradient} alt="" className="grd" />
        <div className="whtCont">
          <div className="tpWht">
            <h4>Forgot Password?</h4>
          </div>
          <p>Confirm the email address linked to your Rankaque account</p>
          <form onSubmit={forgotPassword}>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Email address"
              onChange={(e) => setEmail(e.target.value)}
            />
            <button>{!isLoading ? "Next" : <LoadingOutlined />}</button>
          </form>
          <div className="tnc">
            <p>Terms and Condtions</p>
            <span>.</span>
            <p>Privacy Policy</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotFacilitatorPassword;
