import AttendeeSidebar from "../../../components/AttendeeSidebar/AttendeeSidebar";
import AttendeeTopbar from "../../../components/AttendeeTopbar/AttendeeTopbar";
import "./index.scss";
import Lottie from "lottie-react";
import empty from "../../../images/empty.json";
import { selectAccessToken, selectRefreshToken } from "../../../store/Auth";
import { setUser } from "../../../store/User";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { login } from "../../../store/Auth";

interface AttendeeDashboardProps {}

const AttendeeDashboard: React.FunctionComponent<
  AttendeeDashboardProps
> = () => {
  const token = useSelector(selectAccessToken);
  const refreshToken = useSelector(selectRefreshToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const refreshAccessToken = useCallback(() => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/users/refresh-token`,
      data: {
        refresh: refreshToken,
      },
    })
      .then((res) => {
        dispatch(login(res.data));
      })
      .catch((err) => {
        navigate("/attendee/signin");
      });
  }, [dispatch, navigate, refreshToken]);

  useEffect(() => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/users/user-detail`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        dispatch(setUser(res.data.data));
      })
      .catch((err) => {
        if (err.response.status === 401) {
          refreshAccessToken();
          return;
        }
      });
  }, [token, dispatch, refreshAccessToken]);

  return (
    <div className="dashCont">
      <AttendeeSidebar />
      <div className="flexMain">
        <div className="attDashboard">
          <AttendeeTopbar />
          <div className="centerAtt">
            <p>
              You have <span>(0)</span> pending invite
            </p>
            <div className="whiteAtt">
              <div className="lott">
                <Lottie animationData={empty} />
                <p>No pending invites yet</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttendeeDashboard;
