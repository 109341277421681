import { Input, Skeleton } from "antd";
import ClassLectureDetails from "../../../../components/classLectureDetails/ClassLectureDetails";
import FacClassSidebar from "./FacClassSidebar";
import "./index.scss";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { notification } from "antd";
import React, { useEffect, useState, useCallback } from "react";
import { selectAccessToken, selectRefreshToken } from "../../../../store/Auth";
import moment from "moment";
import Lottie from "lottie-react";
import empty from "../../../../images/empty.json";
import QuestionCard from "../../../../components/questionCard/QuestionCard";
import { login } from "../../../../store/Auth";

interface ClassFeedsProps {}

const ClassFeeds: React.FunctionComponent<ClassFeedsProps> = () => {
  const { id } = useParams();
  const token = useSelector(selectAccessToken);
  const refreshToken = useSelector(selectRefreshToken);
  const [lecture, setLecture] = useState<any>();
  const [isQuestionsLoading, setIsQuestionsLoading] = useState<boolean>(true);

  const [questions, setQuestions] = useState<any>([]);

  const [activeID, setActiveID] = useState<number>(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const refreshAccessToken = useCallback(() => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/users/refresh-token`,
      data: {
        refresh: refreshToken,
      },
    })
      .then((res) => {
        dispatch(login(res.data));
      })
      .catch((err) => {
        navigate("/attendee/signin");
      });
  }, [dispatch, navigate, refreshToken]);

  useEffect(() => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/lectures/${id}/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setLecture(res.data.data);
        const unAnsweredQuestions = res.data.data.questions.filter(
          (question: any) => !question.is_answered
        );
        setQuestions(unAnsweredQuestions);
        setIsQuestionsLoading(false);
      })
      .catch((err) => {
        setIsQuestionsLoading(false);
        if (err.response.status === 401) {
          refreshAccessToken();
          return;
        }
        notification.error({
          message: "Error fetching lecture",
          description: "Oops! there was an error fetching lecture details",
        });
      });
  }, [id, token, refreshAccessToken]);

  const refreshFeed = () => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/lectures/${id}/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        const unAnsweredQuestions = res.data.data.questions.filter(
          (question: any) => !question.is_answered
        );
        setQuestions(unAnsweredQuestions);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          refreshAccessToken();
          return;
        }
      });
  };

  useEffect(() => {
    const timer = setTimeout(() => refreshFeed(), 5000);
    return () => clearTimeout(timer);
  });

  return (
    <div className="flexClass">
      <FacClassSidebar />
      <div className="mainClass">
        <div className="leftClass">
          <Input
            type="search"
            name="search"
            id="search"
            placeholder="Search..."
            suffix={
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17Z"
                  stroke="#4A4E57"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15 15L21 21"
                  stroke="#4A4E57"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            }
          />
          <div className="quesIncl">
            <div className="flxQIncl">
              <h3>Questions asked in this class</h3>
              <div className="fmField">
                <label htmlFor="view">View:</label>
                <select name="view" id="view">
                  <option value="all">All</option>
                  <option value="one">1</option>
                  <option value="two">2</option>
                </select>
              </div>
            </div>
            <div className="flexSort">
              <label htmlFor="sort">Sort by:</label>
              <select name="sortK" id="sortK">
                <option value="all">Keywords</option>
                <option value="one">Most recent</option>
                <option value="two">2</option>
              </select>
            </div>
          </div>
          <div className="quesCont">
            {isQuestionsLoading ? (
              <Skeleton className="pad20" paragraph={{ rows: 5 }} active />
            ) : questions.length > 0 ? (
              <div className="questionsWrapper">
                {questions.map((question: any) => (
                  <QuestionCard
                    key={question.id}
                    id={question.id}
                    question={question.question_text}
                    author={question.question_by}
                    time={moment(question.time_of_question, "HH:mm:ss").format(
                      "hh:mm A"
                    )}
                    isFacilitator={true}
                    voteStatus={false}
                    isAnswered={question.is_answered}
                    numberOfVotes={question.number_of_votes}
                    questions={questions}
                    setQuestions={setQuestions}
                    activeID={activeID}
                    setActiveID={setActiveID}
                    picture={question?.question_by?.profile?.picture}
                  />
                ))}
              </div>
            ) : (
              <div className="emp">
                <Lottie animationData={empty} />
                <p>No questions.</p>
              </div>
            )}
          </div>
        </div>
        <div className="rightClass">
          <ClassLectureDetails lecture={lecture} />
        </div>
      </div>
    </div>
  );
};

export default ClassFeeds;
