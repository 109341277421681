import avatar from "../../../images/defaultPic.png";
import FacilitatorSidebar from "../../../components/FacilitatorSidebar/FacilitatorSidebar";
import "./index.scss";
import Lottie from "lottie-react";
import empty from "../../../images/empty.json";
import { Modal, Skeleton } from "antd";
import { useEffect, useState, useCallback } from "react";
import NewLecture from "../../../components/modalcontent/NewLecture";
import LectureCard from "../../../components/lectureCard/LectureCard";
import SuccessModal from "../../../components/modalcontent/SuccessModal";
import {
  selectAccessToken,
  logout,
  selectRefreshToken,
} from "../../../store/Auth";
import { selectUser, setUser, logOutUser } from "../../../store/User";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { login } from "../../../store/Auth";
import RankedCard from "../../../components/questionCard/RankedCard";

interface FacilitatorDashboardProps {}

const FacilitatorDashboard: React.FunctionComponent<
  FacilitatorDashboardProps
> = () => {
  interface LectureCardProps {
    title: string;
    lecture_date: string;
    lecture_time: string;
    lecture_keywords: any;
    lecture_id: string;
    status: number;
    lecturer: string;
    id: string;
  }

  interface User {
    email: string;
    first_name: string;
    last_name: string;
    phone_number: number;
    profile: {
      picture: string;
    };
  }

  const token = useSelector(selectAccessToken);
  const refreshToken = useSelector(selectRefreshToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user: User = useSelector(selectUser);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isUserLoading, setIsUserLoading] = useState<boolean>(true);
  const [isLecturesLoading, setIsLecturesLoading] = useState<boolean>(true);
  const [successLectureID, setSuccessLectureID] = useState<string>("");
  const [questions, setQuestions] = useState<any>([]);
  const [isQuestionsLoading, setIsQuestionsLoading] = useState<boolean>(true);
  const [lectureID, setLectureID] = useState<string>("");

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleSuccessCancel = () => {
    setIsSuccess(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [lecturesArray, setLecturesArray] = useState<LectureCardProps[]>([]);

  const publishedLectures = lecturesArray.filter(
    (lecture) => lecture.status === 2
  );
  const draftLectures = lecturesArray.filter((lecture) => lecture.status === 1);
  const unpublishedLectures = lecturesArray.filter(
    (lecture) => lecture.status === 3
  );
  const activeLectures = lecturesArray.filter(
    (lecture) => lecture.status === 4
  );

  const refreshAccessToken = useCallback(() => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/users/refresh-token`,
      data: {
        refresh: refreshToken,
      },
    })
      .then((res) => {
        dispatch(login(res.data));
      })
      .catch((err) => {
        navigate("/attendee/signin");
      });
  }, [dispatch, navigate, refreshToken]);

  useEffect(() => {
    if (user) {
      setIsUserLoading(false);
    }
  }, [user]);

  useEffect(() => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/lectures/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setLecturesArray(res.data.data);
        setIsLecturesLoading(false);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          refreshAccessToken();
          return;
        }
      });
  }, [token, refreshAccessToken]);

  useEffect(() => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/users/user-detail`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        dispatch(setUser(res.data.data));
      })
      .catch((err) => {
        if (err.response.status === 401) {
          refreshAccessToken();
          return;
        }
      });
  }, [token, dispatch, refreshAccessToken]);

  useEffect(() => {
    setIsQuestionsLoading(true);
    axios({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/lectures/${lectureID}/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setQuestions(res.data.data.questions);
        setIsQuestionsLoading(false);
      })
      .catch((err) => {
        setIsQuestionsLoading(false);
        if (err.response.status === 401) {
          refreshAccessToken();
          return;
        }
      });
  }, [lectureID, token, refreshAccessToken]);

  const logoutFacilitator = () => {
    dispatch(logout(null));
    dispatch(logOutUser(null));
  };

  return (
    <div className="dashCont">
      <FacilitatorSidebar />
      <div className="flexMain">
        <div className="fstMain">
          <div className="topFstMain">
            {isUserLoading ? (
              <Skeleton active avatar paragraph={{ rows: 5 }} />
            ) : (
              <>
                <div className="icons">
                  <img
                    src={
                      user && user?.profile?.picture !== null
                        ? user?.profile?.picture
                        : avatar
                    }
                    alt=""
                    className="profilePic"
                  />
                  <div className="nvIcons">
                    <button>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 17V16C8.44772 16 8 16.4477 8 17H9ZM15 17H16C16 16.4477 15.5523 16 15 16V17ZM9 18H15V16H9V18ZM14 17V18H16V17H14ZM10 18V17H8V18H10ZM12 20C10.8954 20 10 19.1046 10 18H8C8 20.2091 9.79086 22 12 22V20ZM14 18C14 19.1046 13.1046 20 12 20V22C14.2091 22 16 20.2091 16 18H14Z"
                          fill="#3E4047"
                        />
                        <path
                          d="M4.29289 14.7071L3.58579 14L3.58579 14L4.29289 14.7071ZM4.80372 14.1963L5.51082 14.9034L5.51082 14.9034L4.80372 14.1963ZM19.1963 14.1963L19.9034 13.4892L19.9034 13.4892L19.1963 14.1963ZM19.7071 14.7071L20.4142 14L20.4142 14L19.7071 14.7071ZM12 3L12 4L12 3ZM19 16H5V18H19V16ZM5 16V15.4142H3V16H5ZM5 15.4142L5.51082 14.9034L4.09661 13.4892L3.58579 14L5 15.4142ZM6 13.7224V10H4V13.7224H6ZM18 10V13.7224H20V10H18ZM18.4892 14.9034L19 15.4142L20.4142 14L19.9034 13.4892L18.4892 14.9034ZM19 15.4142V16H21V15.4142H19ZM19 15.4142L19 15.4142H21C21 14.8838 20.7893 14.3751 20.4142 14L19 15.4142ZM18 13.7224C18 14.1653 18.176 14.5901 18.4892 14.9034L19.9034 13.4892C19.9653 13.551 20 13.6349 20 13.7224H18ZM20 10C20 5.58171 16.4183 1.99999 12 2L12 4C15.3137 3.99999 18 6.68629 18 10H20ZM6 10C6 6.6863 8.68628 4.00001 12 4L12 2C7.58171 2.00001 4 5.58173 4 10H6ZM5.51082 14.9034C5.82404 14.5902 6 14.1654 6 13.7224H4C4 13.635 4.03475 13.5511 4.09661 13.4892L5.51082 14.9034ZM5 15.4142H5L3.58579 14C3.21071 14.3751 3 14.8838 3 15.4142H5ZM5 16H3C3 17.1046 3.89543 18 5 18V16ZM19 18C20.1046 18 21 17.1046 21 16H19V18Z"
                          fill="#3E4047"
                        />
                      </svg>
                    </button>
                    <button
                      onClick={() => navigate("/facilitator/edit-profile")}
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M20.1604 6.71287L12.5001 2.29022C12.1907 2.11159 11.8095 2.11159 11.5001 2.29022L3.83984 6.71287C3.53044 6.89151 3.33984 7.22163 3.33984 7.5789V16.4242C3.33984 16.7815 3.53044 17.1116 3.83984 17.2902L11.5001 21.7129C11.8095 21.8915 12.1907 21.8915 12.5001 21.7129L20.1604 17.2902C20.4698 17.1116 20.6604 16.7815 20.6604 16.4242V7.5789C20.6604 7.22163 20.4698 6.89151 20.1604 6.71287Z"
                          stroke="#3E4047"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                          stroke="#3E4047"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                    <button onClick={logoutFacilitator}>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.2929 14.2929C10.9024 14.6834 10.9024 15.3166 11.2929 15.7071C11.6834 16.0976 12.3166 16.0976 12.7071 15.7071L11.2929 14.2929ZM15 12L15.7071 12.7071C15.8946 12.5196 16 12.2652 16 12C16 11.7348 15.8946 11.4804 15.7071 11.2929L15 12ZM12.7071 8.29289C12.3166 7.90237 11.6834 7.90237 11.2929 8.29289C10.9024 8.68342 10.9024 9.31658 11.2929 9.70711L12.7071 8.29289ZM4 11C3.44772 11 3 11.4477 3 12C3 12.5523 3.44772 13 4 13L4 11ZM12.7071 15.7071L15.7071 12.7071L14.2929 11.2929L11.2929 14.2929L12.7071 15.7071ZM15.7071 11.2929L12.7071 8.29289L11.2929 9.70711L14.2929 12.7071L15.7071 11.2929ZM4 13L15 13V11L4 11L4 13Z"
                          fill="#3E4047"
                        />
                        <path
                          d="M9 7V5C9 4.44772 9.44772 4 10 4H19C19.5523 4 20 4.44772 20 5V19C20 19.5523 19.5523 20 19 20H10C9.44771 20 9 19.5523 9 19V17"
                          stroke="#3E4047"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <h1>Welcome {user.first_name},</h1>
              </>
            )}
          </div>
          <div className="btFstMain">
            {isLecturesLoading ? (
              <Skeleton active className="padTp" paragraph={{ rows: 7 }} />
            ) : lecturesArray.length > 0 ? (
              <div className="activeUser">
                <div className="topActUser">
                  <p>
                    You have{" "}
                    {draftLectures.length > 0 && (
                      <>
                        <span className="drft">({draftLectures.length})</span>
                        <span> draft</span>
                      </>
                    )}
                    {activeLectures.length > 0 && (
                      <>
                        {draftLectures.length > 0 && <>, </>}
                        <span className="actv">({activeLectures.length})</span>
                        <span> active</span>
                      </>
                    )}
                    {publishedLectures.length > 0 && (
                      <>
                        {draftLectures.length > 0 && <>, </>}
                        <span className="pubz">
                          ({publishedLectures.length})
                        </span>
                        <span> published</span>
                      </>
                    )}
                    {unpublishedLectures.length > 0 && (
                      <>
                        ,{" "}
                        <span className="unpubz">
                          ({unpublishedLectures.length})
                        </span>
                        <span> unpublished</span>
                      </>
                    )}{" "}
                    lecture{lecturesArray.length > 1 && `s`}
                  </p>
                  <button onClick={showModal}>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 12H18"
                        stroke="#3E4047"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12 18L12 6"
                        stroke="#3E4047"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <div className="lecturesConta">
                  {lecturesArray.map((lecture: LectureCardProps) => (
                    <LectureCard
                      key={lecture.lecture_id}
                      title={lecture.title}
                      id={lecture.id}
                      name={lecture.lecturer}
                      lectureId={lecture.lecture_id}
                      status={lecture.status}
                      date={
                        lecture.lecture_date
                          ? moment(lecture.lecture_date).format("ll")
                          : "Not set"
                      }
                      time={
                        lecture.lecture_time
                          ? moment(lecture.lecture_time, "HH:mm:ss").format(
                              "hh:mm A"
                            )
                          : "Not set"
                      }
                      setLectureID={setLectureID}
                    />
                  ))}
                </div>
                <button onClick={showModal} className="addNew">
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.5 12H18.5"
                      stroke="#787F89"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.5 18L12.5 6"
                      stroke="#787F89"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span>Add New Lecture</span>
                </button>
              </div>
            ) : (
              <div className="newUser">
                <p>You have no recent activity yet.</p>
                <button onClick={showModal}>Create a Lecture</button>
              </div>
            )}
          </div>
        </div>
        <div className="scdMain">
          <div className="tpScMn">
            <h2>Lecture in Session</h2>
            <div className="lectureBox">
              {publishedLectures.length > 0 ? (
                <>
                  {publishedLectures.map((lecture: LectureCardProps) => (
                    <LectureCard
                      key={lecture.lecture_id}
                      title={lecture.title}
                      id={lecture.id}
                      name={lecture.lecturer}
                      lectureId={lecture.lecture_id}
                      status={lecture.status}
                      date={
                        lecture.lecture_date
                          ? moment(lecture.lecture_date).format("ll")
                          : "Not set"
                      }
                      time={
                        lecture.lecture_time
                          ? moment(lecture.lecture_time, "HH:mm:ss").format(
                              "hh:mm A"
                            )
                          : "Not set"
                      }
                      setLectureID={setLectureID}
                    />
                  ))}
                </>
              ) : (
                <div className="liveNo">
                  <h3>You have no live lecture yet</h3>
                  <p>
                    Publish a lecture and share invite link with attendees to
                    begin a live session
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="btScMn">
            <h2>Ranked Questions</h2>
            {isQuestionsLoading ? (
              <Skeleton className="pad20" active paragraph={{ rows: 8 }} />
            ) : (
              <>
                {questions.length < 1 && (
                  <div className="lott">
                    <Lottie animationData={empty} />
                    <p>No question entries yet</p>
                  </div>
                )}
                <div className="rnkDash">
                  {questions.map((question: any) => (
                    <RankedCard
                      author={question.question_by}
                      time={moment(
                        question.time_of_question,
                        "HH:mm:ss"
                      ).format("hh:mm A")}
                      picture={question?.question_by?.profile?.picture}
                      question={question.question_text}
                      key={question.id}
                    />
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Modal
        title={null}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        closable={false}
        zIndex={1}
        width={"600px"}
      >
        <NewLecture
          setLecturesArray={setLecturesArray}
          setIsModalVisible={setIsModalVisible}
          setIsSuccess={setIsSuccess}
          setSuccessLectureID={setSuccessLectureID}
        />
      </Modal>
      <Modal
        title={null}
        visible={isSuccess}
        onCancel={handleSuccessCancel}
        footer={null}
        closable={false}
        zIndex={1}
      >
        <SuccessModal successLectureID={successLectureID} />
      </Modal>
    </div>
  );
};

export default FacilitatorDashboard;
