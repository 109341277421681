import logo from "../../images/logo.png";
import "./Header.scss";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { Link as ScrollLink } from "react-scroll";
import { selectAccessToken } from "../../store/Auth";
import { selectUser } from "../../store/User";
import { useSelector } from "react-redux";

interface HeaderProps {}

const Header: React.FunctionComponent<HeaderProps> = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [position, setPosition] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);
  const navRef = useRef<any>(null);

  const isMobile = window.innerWidth <= 768;
  const cls = visible ? "visible" : "hidden";

  const token = useSelector(selectAccessToken);
  const user = useSelector(selectUser);

  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      let moving = window.pageYOffset;

      setVisible(position > moving);
      if (moving < 80) {
        setVisible(true);
      }
      setPosition(moving);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  useEffect(() => {
    if (isMobile) {
      if (isOpen) {
        navRef.current.style.width = "100%";
        navRef.current.style.padding = "20px";
      } else {
        navRef.current.style.width = "0px";
        navRef.current.style.padding = "0px";
      }
    }
  }, [isOpen, isMobile]);

  return (
    <div>
      <header
        className={
          location.pathname === "/"
            ? `${cls} bxShadow`
            : location.pathname === "/welcome-back"
            ? `${cls} bxShadow`
            : `${cls} normal`
        }
      >
        <div className="logo" onClick={() => window.scrollTo(0, 0)}>
          <Link to={"/"}>
            <img src={logo} alt="" />
          </Link>
        </div>
        {!isMobile ? (
          <nav>
            <ScrollLink to="about">
              <p className="navlnk">About</p>
            </ScrollLink>
            <ScrollLink to="contact">
              <p className="navlnk">Contact</p>
            </ScrollLink>
            {location.pathname === "/" && (
              <>
                {token ? (
                  <button
                    onClick={() =>
                      user?.user_type === 1
                        ? navigate("/facilitator/dashboard")
                        : navigate("/attendee/dashboard")
                    }
                  >
                    Go To Dashboard
                  </button>
                ) : (
                  <button onClick={() => navigate("/welcome-back")}>
                    Sign in
                  </button>
                )}
              </>
            )}
          </nav>
        ) : (
          <div
            onClick={() => setIsOpen(!isOpen)}
            className={isOpen ? "hamburger-menu change" : "hamburger-menu"}
          >
            <div className="bar-top"></div>
            <div className="bar-middle"></div>
            <div className="bar-bottom"></div>
          </div>
        )}
      </header>
      {isMobile && (
        <div className="mobileNavCont" ref={navRef}>
          <nav>
            <div className="ctMenu" onClick={() => setIsOpen(!isOpen)}>
              <div className="closeMenu">
                <div className="bar-one"></div>
                <div className="bar-two"></div>
              </div>
            </div>
            <ScrollLink to="about">
              <p className="navlnk" onClick={() => setIsOpen(!isOpen)}>
                About
              </p>
            </ScrollLink>
            <ScrollLink to="contact">
              <p className="navlnk" onClick={() => setIsOpen(!isOpen)}>
                Contact
              </p>
            </ScrollLink>

            <Link to={"/welcome-back"}>
              <button onClick={() => setIsOpen(!isOpen)}>Sign in</button>
            </Link>
          </nav>
        </div>
      )}
    </div>
  );
};

export default Header;
