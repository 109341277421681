import logo from "../../images/logo2.png";
import profileImg from "../../images/defaultPic.png";
import "./FacilitatorSidebar.scss";
import { useLocation, Link, useParams } from "react-router-dom";
import { Modal } from "antd";
import { useState } from "react";
import { logout } from "../../store/Auth";
import { logOutUser } from "../../store/User";
import { useDispatch } from "react-redux";
import { selectUser } from "../../store/User";
import { useSelector } from "react-redux";

interface FacilitatorSidebarProps {}

const FacilitatorSidebar: React.FunctionComponent<
  FacilitatorSidebarProps
> = () => {
  const location = useLocation();
  const { id } = useParams();
  const isMobile = window.innerWidth <= 768;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const dispatch = useDispatch();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const logoutFacilitator = () => {
    dispatch(logout(null));
    dispatch(logOutUser(null));
  };

  const user = useSelector(selectUser);

  if (isMobile) {
    return (
      <div className="mobileBar">
        <div className="topbar">
          <div className="brLft">
            <button onClick={showModal}>
              <svg
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 34H38"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10 24H38"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10 14H38"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            <img src={logo} alt="" />
          </div>
          <div className="brRght">
            <button>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 17V16C8.44772 16 8 16.4477 8 17H9ZM15 17H16C16 16.4477 15.5523 16 15 16V17ZM9 18H15V16H9V18ZM14 17V18H16V17H14ZM10 18V17H8V18H10ZM12 20C10.8954 20 10 19.1046 10 18H8C8 20.2091 9.79086 22 12 22V20ZM14 18C14 19.1046 13.1046 20 12 20V22C14.2091 22 16 20.2091 16 18H14Z"
                  fill="white"
                />
                <path
                  d="M4.29289 14.7071L3.58579 14L3.58579 14L4.29289 14.7071ZM4.80372 14.1963L5.51082 14.9034L5.51082 14.9034L4.80372 14.1963ZM19.1963 14.1963L19.9034 13.4892L19.9034 13.4892L19.1963 14.1963ZM19.7071 14.7071L20.4142 14L20.4142 14L19.7071 14.7071ZM12 3L12 4L12 3ZM19 16H5V18H19V16ZM5 16V15.4142H3V16H5ZM5 15.4142L5.51082 14.9034L4.09661 13.4892L3.58579 14L5 15.4142ZM6 13.7224V10H4V13.7224H6ZM18 10V13.7224H20V10H18ZM18.4892 14.9034L19 15.4142L20.4142 14L19.9034 13.4892L18.4892 14.9034ZM19 15.4142V16H21V15.4142H19ZM19 15.4142L19 15.4142H21C21 14.8838 20.7893 14.3751 20.4142 14L19 15.4142ZM18 13.7224C18 14.1653 18.176 14.5901 18.4892 14.9034L19.9034 13.4892C19.9653 13.551 20 13.6349 20 13.7224H18ZM20 10C20 5.58171 16.4183 1.99999 12 2L12 4C15.3137 3.99999 18 6.68629 18 10H20ZM6 10C6 6.6863 8.68628 4.00001 12 4L12 2C7.58171 2.00001 4 5.58173 4 10H6ZM5.51082 14.9034C5.82404 14.5902 6 14.1654 6 13.7224H4C4 13.635 4.03475 13.5511 4.09661 13.4892L5.51082 14.9034ZM5 15.4142H5L3.58579 14C3.21071 14.3751 3 14.8838 3 15.4142H5ZM5 16H3C3 17.1046 3.89543 18 5 18V16ZM19 18C20.1046 18 21 17.1046 21 16H19V18Z"
                  fill="white"
                />
              </svg>
            </button>
            <img
              src={
                user?.profile?.picture !== null
                  ? user?.profile?.picture
                  : profileImg
              }
              className="prpic"
              alt=""
            />
          </div>
        </div>
        <Modal
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
          closable={false}
          style={{
            margin: "0px",
            top: "0px",
            padding: "0px",
          }}
          bodyStyle={{
            height: "100vh",
            padding: "0px",
          }}
          width="70%"
        >
          <div className="navBarMb">
            <div className="flxedn">
              <button className="close" onClick={handleCancel}>
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M34.9999 34.9999L5 5"
                    stroke="#3E4047"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M35.0001 5L5 35.0001"
                    stroke="#3E4047"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
            <div className="navs">
              <Link to="/facilitator/dashboard">
                <button
                  className={
                    location.pathname === "/facilitator/dashboard"
                      ? "active"
                      : ""
                  }
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.6585 9.70055L12.6585 3.57555C12.2815 3.24565 11.7185 3.24565 11.3415 3.57555L4.3415 9.70055C4.12448 9.89044 4 10.1648 4 10.4531V18.9994C4 19.5516 4.44772 19.9994 5 19.9994H9C9.55228 19.9994 10 19.5516 10 18.9994V14.9994C10 14.4471 10.4477 13.9994 11 13.9994H13C13.5523 13.9994 14 14.4471 14 14.9994V18.9994C14 19.5516 14.4477 19.9994 15 19.9994H19C19.5523 19.9994 20 19.5516 20 18.9994V10.4531C20 10.1648 19.8755 9.89044 19.6585 9.70055Z"
                      stroke="#787F89"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span>Home</span>
                </button>
              </Link>
              <Link to="/facilitator/lectures">
                <button
                  className={
                    location.pathname === "/facilitator/lectures"
                      ? "active"
                      : location.pathname === `/facilitator/lecture/${id}`
                      ? "active"
                      : ""
                  }
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.78998 15.07L6.32571 14.2256C6.07184 14.0645 5.75898 14.0262 5.47375 14.1213L5.78998 15.07ZM3 16L2.05132 15.6838C1.93154 16.0431 2.02506 16.4393 2.29289 16.7071C2.56073 16.9749 2.95689 17.0685 3.31623 16.9487L3 16ZM3.92999 13.21L4.87868 13.5262C4.97375 13.241 4.93545 12.9282 4.77438 12.6743L3.92999 13.21ZM9 17C12.866 17 16 13.866 16 10H14C14 12.7614 11.7614 15 9 15V17ZM5.25425 15.9144C6.33796 16.602 7.62403 17 9 17V15C8.01456 15 7.09852 14.7159 6.32571 14.2256L5.25425 15.9144ZM5.47375 14.1213L2.68377 15.0513L3.31623 16.9487L6.10621 16.0187L5.47375 14.1213ZM3.94868 16.3162L4.87868 13.5262L2.98131 12.8938L2.05132 15.6838L3.94868 16.3162ZM2 10C2 11.376 2.39802 12.662 3.08561 13.7458L4.77438 12.6743C4.28406 11.9015 4 10.9854 4 10H2ZM9 3C5.13401 3 2 6.13401 2 10H4C4 7.23858 6.23858 5 9 5V3ZM16 10C16 6.13401 12.866 3 9 3V5C11.7614 5 14 7.23858 14 10H16Z"
                      fill="#787F89"
                    />
                    <path
                      d="M9.33789 15.9896C10.1586 18.3254 12.3838 20 15.0001 20C16.1808 20 17.2819 19.659 18.2101 19.07L21.0001 20L20.0701 17.21L20.2122 16.9744C20.7136 16.0977 21.0001 15.0823 21.0001 14C21.0001 10.6863 18.3138 8 15.0001 8L14.7752 8.00414L14.6623 8.01014"
                      stroke="#787F89"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span>Lectures</span>
                </button>
              </Link>
              <Link to="/facilitator/invites">
                <button
                  className={
                    location.pathname === "/facilitator/invites" ? "active" : ""
                  }
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18 21C19.6569 21 21 19.6569 21 18C21 16.3431 19.6569 15 18 15C16.3431 15 15 16.3431 15 18C15 19.6569 16.3431 21 18 21Z"
                      stroke="#787F89"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9 13.5L15 16.5"
                      stroke="#787F89"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M6 15C7.65685 15 9 13.6569 9 12C9 10.3431 7.65685 9 6 9C4.34315 9 3 10.3431 3 12C3 13.6569 4.34315 15 6 15Z"
                      stroke="#787F89"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M15 7.5L9 10.5"
                      stroke="#787F89"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M18 9C19.6569 9 21 7.65685 21 6C21 4.34315 19.6569 3 18 3C16.3431 3 15 4.34315 15 6C15 7.65685 16.3431 9 18 9Z"
                      stroke="#787F89"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span>Invites</span>
                </button>
              </Link>
              <Link to="/facilitator/profile">
                <button
                  className={
                    location.pathname === "/facilitator/profile"
                      ? "active"
                      : location.pathname === "/facilitator/edit-profile"
                      ? "active"
                      : ""
                  }
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4 21C4 18.2386 7.58172 16 12 16C16.4183 16 20 18.2386 20 21"
                      stroke="#787F89"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12 13C14.7614 13 17 10.7614 17 8C17 5.23858 14.7614 3 12 3C9.23858 3 7 5.23858 7 8C7 10.7614 9.23858 13 12 13Z"
                      stroke="#787F89"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span>Profile</span>
                </button>
              </Link>
              <button onClick={logoutFacilitator}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.2922 24.2929C18.9017 24.6834 18.9017 25.3166 19.2922 25.7071C19.6828 26.0976 20.3159 26.0976 20.7065 25.7071L19.2922 24.2929ZM24.9993 20L25.7065 20.7071C25.894 20.5196 25.9993 20.2652 25.9993 20C25.9993 19.7348 25.894 19.4804 25.7065 19.2929L24.9993 20ZM20.7065 14.2929C20.3159 13.9024 19.6828 13.9024 19.2922 14.2929C18.9017 14.6834 18.9017 15.3166 19.2922 15.7071L20.7065 14.2929ZM6.66602 19C6.11373 19 5.66602 19.4477 5.66602 20C5.66602 20.5523 6.11373 21 6.66602 21L6.66602 19ZM20.7065 25.7071L25.7065 20.7071L24.2922 19.2929L19.2922 24.2929L20.7065 25.7071ZM25.7065 19.2929L20.7065 14.2929L19.2922 15.7071L24.2922 20.7071L25.7065 19.2929ZM6.66602 21L24.9993 21V19L6.66602 19L6.66602 21Z"
                    fill="#787F89"
                  />
                  <path
                    d="M15 11.668V8.33464C15 7.41416 15.7462 6.66797 16.6667 6.66797H31.6667C32.5871 6.66797 33.3333 7.41416 33.3333 8.33464V31.668C33.3333 32.5884 32.5871 33.3346 31.6667 33.3346H16.6667C15.7462 33.3346 15 32.5884 15 31.668V28.3346"
                    stroke="#787F89"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span>Logout</span>
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }

  return (
    <div className="sidebar">
      <Link to="/">
        <div className="logo">
          <img src={logo} alt="" />
        </div>
      </Link>
      <Link to="/facilitator/dashboard">
        <div
          className={
            location.pathname === "/facilitator/dashboard"
              ? "nvLinksSB nvActive"
              : "nvLinksSB"
          }
        >
          <svg
            width="30"
            height="31"
            viewBox="0 0 30 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M27.7635 12.1676L16.0969 1.95925C15.4685 1.40942 14.5302 1.40942 13.9018 1.95925L2.23517 12.1676C1.87348 12.4841 1.66602 12.9413 1.66602 13.4219V27.6656C1.66602 28.5861 2.41221 29.3323 3.33268 29.3323H9.99935C10.9198 29.3323 11.666 28.5861 11.666 27.6656V20.9989C11.666 20.0785 12.4122 19.3323 13.3327 19.3323H16.666C17.5865 19.3323 18.3327 20.0785 18.3327 20.9989V27.6656C18.3327 28.5861 19.0789 29.3323 19.9993 29.3323H26.666C27.5865 29.3323 28.3327 28.5861 28.3327 27.6656V13.4219C28.3327 12.9413 28.1252 12.4841 27.7635 12.1676Z"
              stroke="#9DA3AB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p>Home</p>
        </div>
      </Link>
      <Link to="/facilitator/lectures">
        <div
          className={
            location.pathname === "/facilitator/lectures"
              ? "nvLinksSB nvActive"
              : location.pathname === `/facilitator/lecture/${id}`
              ? "nvLinksSB nvActive"
              : "nvLinksSB"
          }
        >
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.64996 25.118L10.1857 24.2736C9.93183 24.1125 9.61896 24.0742 9.33374 24.1693L9.64996 25.118ZM5 26.668L4.05132 26.3517C3.93154 26.7111 4.02506 27.1072 4.29289 27.3751C4.56073 27.6429 4.95689 27.7364 5.31623 27.6167L5 26.668ZM6.54999 22.018L7.49867 22.3342C7.59375 22.049 7.55545 21.7361 7.39437 21.4823L6.54999 22.018ZM15 27.668C21.0751 27.668 26 22.7431 26 16.668H24C24 21.6385 19.9706 25.668 15 25.668V27.668ZM9.11423 25.9624C10.8168 27.0426 12.8369 27.668 15 27.668V25.668C13.2274 25.668 11.5773 25.1565 10.1857 24.2736L9.11423 25.9624ZM9.33374 24.1693L4.68377 25.7193L5.31623 27.6167L9.96619 26.0667L9.33374 24.1693ZM5.94868 26.9842L7.49867 22.3342L5.6013 21.7018L4.05132 26.3517L5.94868 26.9842ZM4 16.668C4 18.8311 4.62539 20.8512 5.7056 22.5537L7.39437 21.4823C6.51142 20.0906 6 18.4405 6 16.668H4ZM15 5.66797C8.92487 5.66797 4 10.5928 4 16.668H6C6 11.6974 10.0294 7.66797 15 7.66797V5.66797ZM26 16.668C26 10.5928 21.0751 5.66797 15 5.66797V7.66797C19.9706 7.66797 24 11.6974 24 16.668H26Z"
              fill="#9DA3AB"
            />
            <path
              d="M15.5625 26.648C16.9304 30.541 20.6391 33.332 24.9996 33.332C26.9674 33.332 28.8026 32.7637 30.3496 31.782L34.9996 33.332L33.4496 28.682L33.6863 28.2894C34.522 26.8282 34.9996 25.1359 34.9996 23.332C34.9996 17.8092 30.5224 13.332 24.9996 13.332L24.6247 13.3389L24.4365 13.3489"
              stroke="#9DA3AB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p>Lectures</p>
        </div>
      </Link>
      <Link to="/facilitator/invites">
        <div
          className={
            location.pathname === "/facilitator/invites"
              ? "nvLinksSB nvActive"
              : "nvLinksSB"
          }
        >
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M30 35C32.7614 35 35 32.7614 35 30C35 27.2386 32.7614 25 30 25C27.2386 25 25 27.2386 25 30C25 32.7614 27.2386 35 30 35Z"
              stroke="#9DA3AB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15 22.5L25 27.5"
              stroke="#9DA3AB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10 25C12.7614 25 15 22.7614 15 20C15 17.2386 12.7614 15 10 15C7.23858 15 5 17.2386 5 20C5 22.7614 7.23858 25 10 25Z"
              stroke="#9DA3AB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M25 12.5L15 17.5"
              stroke="#9DA3AB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M30 15C32.7614 15 35 12.7614 35 10C35 7.23858 32.7614 5 30 5C27.2386 5 25 7.23858 25 10C25 12.7614 27.2386 15 30 15Z"
              stroke="#9DA3AB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p>Invites</p>
        </div>
      </Link>
      <Link to="/facilitator/profile">
        <div
          className={
            location.pathname === "/facilitator/profile"
              ? "nvLinksSB nvActive"
              : location.pathname === "/facilitator/edit-profile"
              ? "nvLinksSB nvActive"
              : "nvLinksSB"
          }
        >
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.66602 35.0013C6.66602 30.3989 12.6356 26.668 19.9993 26.668C27.3631 26.668 33.3327 30.3989 33.3327 35.0013"
              stroke="#9DA3AB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.9993 21.6667C24.6017 21.6667 28.3327 17.9357 28.3327 13.3333C28.3327 8.73096 24.6017 5 19.9993 5C15.397 5 11.666 8.73096 11.666 13.3333C11.666 17.9357 15.397 21.6667 19.9993 21.6667Z"
              stroke="#9DA3AB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p>Profile</p>
        </div>
      </Link>
      <div className="nvLinksSB" onClick={logoutFacilitator}>
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.2922 24.2929C18.9017 24.6834 18.9017 25.3166 19.2922 25.7071C19.6828 26.0976 20.3159 26.0976 20.7065 25.7071L19.2922 24.2929ZM24.9993 20L25.7065 20.7071C25.894 20.5196 25.9993 20.2652 25.9993 20C25.9993 19.7348 25.894 19.4804 25.7065 19.2929L24.9993 20ZM20.7065 14.2929C20.3159 13.9024 19.6828 13.9024 19.2922 14.2929C18.9017 14.6834 18.9017 15.3166 19.2922 15.7071L20.7065 14.2929ZM6.66602 19C6.11373 19 5.66602 19.4477 5.66602 20C5.66602 20.5523 6.11373 21 6.66602 21L6.66602 19ZM20.7065 25.7071L25.7065 20.7071L24.2922 19.2929L19.2922 24.2929L20.7065 25.7071ZM25.7065 19.2929L20.7065 14.2929L19.2922 15.7071L24.2922 20.7071L25.7065 19.2929ZM6.66602 21L24.9993 21V19L6.66602 19L6.66602 21Z"
            fill="#9DA3AB"
          />
          <path
            d="M15 11.668V8.33464C15 7.41416 15.7462 6.66797 16.6667 6.66797H31.6667C32.5871 6.66797 33.3333 7.41416 33.3333 8.33464V31.668C33.3333 32.5884 32.5871 33.3346 31.6667 33.3346H16.6667C15.7462 33.3346 15 32.5884 15 31.668V28.3346"
            stroke="#9DA3AB"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <p>Logout</p>
      </div>
    </div>
  );
};

export default FacilitatorSidebar;
