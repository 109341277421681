import Header from "../../../../components/header/Header";
import bgGradient from "../../../../images/bgrg.png";
import OtpInput from "react-otp-input";
import { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

interface VerifyOtpProps {}

const VerifyOtp: React.FunctionComponent<VerifyOtpProps> = () => {
  const [OTP, setOTP] = useState<any>("----");
  const { email } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const openSuccessNotification = () => {
    notification.success({
      message: "Account verified",
    });
  };

  const openErrorNotification = (text: string) => {
    notification.error({
      message: "Error",
      description: text,
    });
  };

  const verifyOtpValue = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (OTP !== "----") {
      setIsLoading(true);
      axios({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/users/verify-otp`,
        data: {
          email: email,
          otp: OTP,
        },
      })
        .then((res) => {
          openSuccessNotification();
          setIsLoading(false);
          navigate("/account-verified");
        })
        .catch((err) => {
          openErrorNotification(err.response.data.message);
          setIsLoading(false);
        });
    }
  };

  return (
    <div className="onbdMain">
      <Header />
      <div className="onbdCont">
        <img src={bgGradient} alt="" className="grd" />
        <div className="whtCont">
          <h4>Verify account</h4>

          <h3>
            Please enter the One-Time Password (OTP) to verify your account
          </h3>
          <p className="ver">A One-Time Password has been sent to {email}</p>
          <form onSubmit={(e) => verifyOtpValue(e)}>
            <div className="otpCont">
              <OtpInput
                value={OTP}
                onChange={(otp: any) => setOTP(otp)}
                numInputs={4}
              />
            </div>
            <button>
              {!isLoading ? "Verify Account" : <LoadingOutlined />}
            </button>
          </form>
          <div className="tnc">
            <p>Terms and Condtions</p>
            <span>.</span>
            <p>Privacy Policy</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyOtp;
