import Header from "../../../components/header/Header";
import bgGradient from "../../../images/bgrg.png";
import { Input, notification } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { LoadingOutlined } from "@ant-design/icons";
import { login } from "../../../store/Auth";
import { setUser } from "../../../store/User";
import { useDispatch } from "react-redux";

interface SigninAttendeeProps {}

const SigninAttendee: React.FunctionComponent<SigninAttendeeProps> = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location: any = useLocation();

  const openErrorNotification = (text: string) => {
    notification.error({
      message: "Error",
      description: text,
    });
  };

  const signin = (e: any) => {
    e.preventDefault();
    if (email && password !== "") {
      setIsLoading(true);
      axios({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/users/login`,
        data: {
          email: email,
          password: password,
        },
      })
        .then((res) => {
          dispatch(login(res.data.data.token));
          dispatch(setUser(res.data.data));
          setIsLoading(false);
          if (
            location.state &&
            location.state.from.pathname.includes("/attendee/lecture/") &&
            res.data.data.user_type === 2
          ) {
            return navigate(location.state.from.pathname);
          }
          res.data.data.user_type === 1
            ? navigate("/facilitator/dashboard")
            : res.data.data.user_type === 2
            ? navigate("/attendee/dashboard")
            : navigate("");
        })
        .catch((err) => {
          openErrorNotification(err.response.data.message);
          setIsLoading(false);
        });
    }
  };

  return (
    <div className="onbdMain">
      <Header />
      <div className="onbdCont">
        <img src={bgGradient} alt="" className="grd" />
        <div className="whtCont">
          <div className="tpWht">
            <h4>Sign in</h4>
            <Link to="/attendee/signup">
              <p>I don't have an account</p>
            </Link>
          </div>
          <form>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Email address"
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input.Password
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <button onClick={signin}>
              {!isLoading ? "Log in to Account" : <LoadingOutlined />}
            </button>
          </form>
          <Link to={"/forgot-password"}>
            <p className="fget">Forgot password?</p>
          </Link>
          <div className="tnc">
            <p>Terms and Condtions</p>
            <span>.</span>
            <p>Privacy Policy</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SigninAttendee;
