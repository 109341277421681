import profileImg from "../../images/defaultPic.png";

interface RankedCardProps {
  question: string;
  author: {
    first_name: string;
    last_name: string;
  };
  time: string;
  picture: string;
}

const RankedCard: React.FunctionComponent<RankedCardProps> = ({
  question,
  author,
  time,
  picture,
}) => {
  return (
    <div className="rankCd">
      <p>{question}</p>
      <div className="flxTimeSt">
        <div className="post">
          <img src={author ? picture : profileImg} alt="" />
          <span className="author">
            <span>
              {author
                ? `${author.first_name} ${author.last_name}`
                : "Anonymous"}
            </span>
          </span>
        </div>
        <div className="time">
          <span className="tme">
            Time stamp:{" "}
            <span className="ct">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
                  stroke="#2563EB"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8 4.6665V7.99984H11.3333"
                  stroke="#2563EB"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span>{time}</span>
            </span>
          </span>
        </div>
      </div>
      <p className="tag">Most relevant</p>
      <div className="border"></div>
    </div>
  );
};

export default RankedCard;
