import { Input, Skeleton } from "antd";
import FacClassSidebar from "./FacClassSidebar";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { notification } from "antd";
import React, { useEffect, useState, useCallback } from "react";
import { selectAccessToken, selectRefreshToken } from "../../../../store/Auth";
import RankedCard from "../../../../components/questionCard/RankedCard";
import moment from "moment";
import Lottie from "lottie-react";
import empty from "../../../../images/empty.json";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { login } from "../../../../store/Auth";

interface RankedQuestionsProps {}

const RankedQuestions: React.FunctionComponent<RankedQuestionsProps> = () => {
  const { id } = useParams();
  const token = useSelector(selectAccessToken);
  const refreshToken = useSelector(selectRefreshToken);
  const [isQuestionsLoading, setIsQuestionsLoading] = useState<boolean>(true);

  const [questions, setQuestions] = useState<any>([]);
  const [chartData, setChartData] = useState<number[]>([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const refreshAccessToken = useCallback(() => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/users/refresh-token`,
      data: {
        refresh: refreshToken,
      },
    })
      .then((res) => {
        dispatch(login(res.data));
      })
      .catch((err) => {
        navigate("/attendee/signin");
      });
  }, [dispatch, navigate, refreshToken]);

  useEffect(() => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/lectures/${id}/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        const chartRates: number[] = res.data.data.chart_rating.map(
          (data: { percentage: number }) => data.percentage
        );
        setChartData(chartRates);
        setQuestions(res.data.data.questions);
        setIsQuestionsLoading(false);
      })
      .catch((err) => {
        setIsQuestionsLoading(false);
        if (err.response.status === 401) {
          refreshAccessToken();
          return;
        }
        notification.error({
          message: "Error fetching lecture",
          description: "Oops! there was an error fetching lecture details",
        });
      });
  }, [id, token, refreshAccessToken]);

  const questionsData = {
    labels: ["Most relevant", "Closely related", "Least relevant"],
    datasets: [
      {
        data: chartData,
        backgroundColor: ["#0D9488", "#F59E0B", "#DC2626"],
        hoverOffset: 4,
      },
    ],
  };

  ChartJS.register(ArcElement, Tooltip, Legend);

  const options = {
    legend: {
      display: false,
      position: "right",
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
  };

  return (
    <div className="flexClass">
      <FacClassSidebar />
      {isQuestionsLoading ? (
        <Skeleton className="pad20" paragraph={{ rows: 15 }} active />
      ) : (
        <div className="mainClass rak">
          <div className="leftClass rnk">
            <h1>Ranked Questions</h1>
            <div className="fmField">
              <label htmlFor="view">View:</label>
              <select name="view" id="view">
                <option value="all">All</option>
                <option value="one">1</option>
                <option value="two">2</option>
              </select>
            </div>
            {questions.length > 0 ? (
              <div className="quesContR">
                <div className="rkCont questionsWrapper">
                  {questions.map((question: any) => (
                    <RankedCard
                      author={question.question_by}
                      time={moment(
                        question.time_of_question,
                        "HH:mm:ss"
                      ).format("hh:mm A")}
                      picture={question?.question_by?.profile?.picture}
                      question={question.question_text}
                      key={question.id}
                    />
                  ))}
                </div>
              </div>
            ) : (
              <div className="emp">
                <Lottie animationData={empty} />
                <p>No questions.</p>
              </div>
            )}
          </div>
          <div className="rightClassRQ">
            <Input
              type="search"
              name="search"
              id="search"
              placeholder="Search..."
              suffix={
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17Z"
                    stroke="#4A4E57"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M15 15L21 21"
                    stroke="#4A4E57"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              }
            />
            <div className="flexSort">
              <label htmlFor="sort">Sort by:</label>
              <select name="sortK" id="sortK">
                <option value="all">Keywords</option>
                <option value="one">1</option>
                <option value="two">2</option>
              </select>
              <select name="sortM" id="sortM">
                <option value="all">Most recent</option>
                <option value="one">1</option>
                <option value="two">2</option>
              </select>
            </div>
            <div className="chartCont">
              <p>Total number of questions</p>
              <Doughnut data={questionsData} options={options} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RankedQuestions;
