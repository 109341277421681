import { useEffect } from "react";
import { Link } from "react-router-dom";
import Contact from "../../../components/contact/Contact";
import Footer from "../../../components/footer/Footer";
import Header from "../../../components/header/Header";
import "./welcome.scss";

interface WelcomeBackProps {}

const WelcomeBack: React.FunctionComponent<WelcomeBackProps> = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header />
      <div className="wHero">
        <h1>Welcome Back!</h1>
        <div className="btnWl">
          <Link to={"/facilitator/signin"}>
            <button className="faci">Continue as a Facilitator</button>
          </Link>
          <Link to={"/attendee/signin"}>
            <button className="att">Continue as an Attendee</button>
          </Link>
        </div>
      </div>
      <Contact />
      <Footer />
    </div>
  );
};

export default WelcomeBack;
