import success from "../../images/success.json";
import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom";

interface SuccessModalProps {
  successLectureID: string;
}

const SuccessModal: React.FunctionComponent<SuccessModalProps> = ({
  successLectureID,
}) => {
  const navigate = useNavigate();
  return (
    <div className="modCont">
      <div className="succs">
        <h2>Success!</h2>
        <div className="lott">
          <Lottie animationData={success} />
        </div>
        <p>Your Lecture has been saved</p>
        <button
          onClick={() => navigate(`/facilitator/lecture/${successLectureID}`)}
        >
          View Lecture Details
        </button>
      </div>
    </div>
  );
};

export default SuccessModal;
