import "./index.scss";
import profileImg from "../../images/defaultPic.png";
import moment from "moment";
import { selectUser } from "../../store/User";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "antd";

interface ClassLectureDetailsProps {
  lecture: {
    title: string;
    description: string;
    lecture_time: string;
    lecture_date: string;
    lecture_keys: { id: number; keyword: string }[];
    lecturer: string;
    questions: [];
    in_class: boolean;
  };
}

interface User {
  email: string;
  first_name: string;
  last_name: string;
  phone_number: number;
  user_type: number;
}

const ClassLectureDetails: React.FunctionComponent<
  ClassLectureDetailsProps
> = ({ lecture }) => {
  const user: User = useSelector(selectUser);
  const navigate = useNavigate();
  return (
    <div className="classLDeets">
      <div className="topDetails">
        <img src={profileImg} alt="" />
        <h3>
          {user.first_name} {user.last_name}
        </h3>
        <p className="user_type">
          {user.user_type === 2 ? "ATTENDEE" : "FACILITATOR"}
        </p>
        <button
          onClick={
            user.user_type === 2
              ? () => navigate("/attendee/dashboard")
              : () => navigate("/facilitator/dashboard")
          }
        >
          Go to My Dashboard
        </button>
      </div>
      {lecture ? (
        <div className="remDetails">
          <div className="fstMain">
            <h1>Lecture Details</h1>
            <label htmlFor="title">TITLE</label>
            <h3>{lecture.title}</h3>
            <div className="flCol">
              <label htmlFor="desc">DESCRIPTION</label>
              <textarea
                readOnly
                name="desc"
                id="desc"
                cols={30}
                rows={5}
                defaultValue={lecture.description}
              ></textarea>
            </div>
            <label htmlFor="time">TIME SCHEDULE</label>
            <div className="TIMDcNT">
              <div className="date">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19 4H5C4.44772 4 4 4.44772 4 5V19C4 19.5523 4.44772 20 5 20H19C19.5523 20 20 19.5523 20 19V5C20 4.44772 19.5523 4 19 4Z"
                    stroke="#1D4ED8"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16 16H16.002V16.002H16V16Z"
                    stroke="#1D4ED8"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12 16H12.002V16.002H12V16Z"
                    stroke="#1D4ED8"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8 16H8.002V16.002H8V16Z"
                    stroke="#1D4ED8"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16 12H16.002V12.002H16V12Z"
                    stroke="#1D4ED8"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12 12H12.002V12.002H12V12Z"
                    stroke="#1D4ED8"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8 12H8.002V12.002H8V12Z"
                    stroke="#1D4ED8"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M4 8H20"
                    stroke="#1D4ED8"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16 2V4"
                    stroke="#1D4ED8"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8 2V4"
                    stroke="#1D4ED8"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span>
                  {lecture.lecture_date
                    ? moment(lecture.lecture_date).format("ll")
                    : "Not set"}
                </span>
              </div>
              <div className="time">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                    stroke="#1D4ED8"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12 7V12H17"
                    stroke="#1D4ED8"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span>
                  {lecture.lecture_time
                    ? moment(lecture.lecture_time, "HH:mm:ss").format("hh:mm A")
                    : "Not set"}
                </span>
              </div>
            </div>
            <div className="mgTp">
              {user.user_type === 1 && (
                <>
                  <label htmlFor="time">KEYWORDS</label>
                  {lecture && lecture?.lecture_keys?.length > 0 ? (
                    <div className="keywCont">
                      {lecture?.lecture_keys.map((keyword) => (
                        <span key={keyword.id} className="keyW">
                          {keyword.keyword}
                        </span>
                      ))}
                    </div>
                  ) : (
                    <div className="keywCont">No keywords</div>
                  )}
                </>
              )}
            </div>
            <label htmlFor="time">FACILITATOR IN CHARGE</label>
            <p className="faci">{lecture.lecturer}</p>
          </div>
        </div>
      ) : (
        <Skeleton className="pad20" paragraph={{ rows: 5 }} active />
      )}
    </div>
  );
};

export default ClassLectureDetails;
