import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  refreshToken: null,
  accessToken: null,
};

const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      state.refreshToken = action.payload.refresh;
      state.accessToken = action.payload.access;
    },
    logout: (state, action) => {
      state.refreshToken = action.payload;
      state.accessToken = action.payload;
    },
  },
});

export default AuthSlice.reducer;

export const { login, logout } = AuthSlice.actions;

export const selectRefreshToken = (state: { auth: { refreshToken: string } }) =>
  state.auth.refreshToken;

export const selectAccessToken = (state: { auth: { accessToken: string } }) =>
  state.auth.accessToken;