// import { Input } from "antd";
import ClassLectureDetails from "../../../../components/classLectureDetails/ClassLectureDetails";
import FacClassSidebar from "./FacClassSidebar";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { notification, Skeleton } from "antd";
import React, { useEffect, useState, useCallback } from "react";
import { selectAccessToken, selectRefreshToken } from "../../../../store/Auth";
import moment from "moment";
import Lottie from "lottie-react";
import empty from "../../../../images/empty.json";
import QuestionCard from "../../../../components/questionCard/QuestionCard";
import { login } from "../../../../store/Auth";

interface AnsweredQuestionsProps {}

const AnsweredQuestions: React.FunctionComponent<
  AnsweredQuestionsProps
> = () => {
  const { id } = useParams();
  const token = useSelector(selectAccessToken);
  const refreshToken = useSelector(selectRefreshToken);
  const [lecture, setLecture] = useState<any>();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isQuestionsLoading, setIsQuestionsLoading] = useState<boolean>(true);

  const [questions, setQuestions] = useState<any>([]);
  const [activeID, setActiveID] = useState<number>(0);

  const refreshAccessToken = useCallback(() => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/users/refresh-token`,
      data: {
        refresh: refreshToken,
      },
    })
      .then((res) => {
        dispatch(login(res.data));
      })
      .catch((err) => {
        navigate("/attendee/signin");
      });
  }, [dispatch, navigate, refreshToken]);

  useEffect(() => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/lectures/${id}/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setLecture(res.data.data);
        const answeredQuestions = res.data.data.questions.filter(
          (question: any) => question.is_answered
        );
        setQuestions(answeredQuestions);
        setIsQuestionsLoading(false);
      })
      .catch((err) => {
        setIsQuestionsLoading(false);
        if (err.response.status === 401) {
          refreshAccessToken();
          return;
        }
        notification.error({
          message: "Error fetching lecture",
          description: "Oops! there was an error fetching lecture details",
        });
      });
  }, [id, token, refreshAccessToken]);
  return (
    <div className="flexClass">
      <FacClassSidebar />
      <div className="mainClass">
        <div className="leftClass">
          <h1>Answered Questions</h1>
          <div className="quesIncl">
            <div className="flxQIncl">
              <h3>Questions asked in this class</h3>
              <div className="fmField">
                <label htmlFor="view">View:</label>
                <select name="view" id="view">
                  <option value="all">All</option>
                  <option value="one">1</option>
                  <option value="two">2</option>
                </select>
              </div>
            </div>
            <div className="flexSort">
              <label htmlFor="sort">Sort by:</label>
              <select name="sortK" id="sortK">
                <option value="all">Keywords</option>
                <option value="one">Most recent</option>
                <option value="two">2</option>
              </select>
            </div>
          </div>
          <div className="quesCont">
            {isQuestionsLoading ? (
              <Skeleton className="pad20" paragraph={{ rows: 5 }} active />
            ) : questions.length > 0 ? (
              <div className="questionsWrapper">
                {questions.map((question: any) => (
                  <QuestionCard
                    key={question.id}
                    question={question.question_text}
                    author={question.question_by}
                    time={moment(question.time_of_question, "HH:mm:ss").format(
                      "hh:mm A"
                    )}
                    isFacilitator={true}
                    voteStatus={false}
                    isAnswered={true}
                    numberOfVotes={question.number_of_votes}
                    setActiveID={setActiveID}
                    activeID={activeID}
                    setQuestions={setQuestions}
                    picture={question?.question_by?.profile?.picture}
                  />
                ))}
              </div>
            ) : (
              <div className="emp">
                <Lottie animationData={empty} />
                <p>No answered questions yet.</p>
              </div>
            )}
          </div>
        </div>
        <div className="rightClass">
          <ClassLectureDetails lecture={lecture} />
        </div>
      </div>
    </div>
  );
};

export default AnsweredQuestions;
