import "./contact.scss";
import { Element } from "react-scroll";

interface ContactProps {}

const Contact: React.FunctionComponent<ContactProps> = () => {
  const isMobile = window.innerWidth <= 768;

  return (
    <>
      <Element name="contact"></Element>
      <div className="ctcCont">
        <div className="flxCtc">
          <div className="lftCtc">
            <h2>
              Have some {!isMobile && <br />} questions for the{" "}
              {!isMobile && <br />} Team at Rankaque?
            </h2>
            <p>We would love to hear from you.</p>
          </div>
          <div className="rghtCtc">
            <form>
              <input type="text" name="fullname" placeholder="Full name" />
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Email address"
              />
              <textarea
                name="message"
                id="message"
                cols={30}
                rows={10}
                placeholder="Your message"
              ></textarea>
              <button className="faci">Send Message</button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
