import { BrowserRouter, Routes, Route } from "react-router-dom";
import SigninAttendee from "./pages/general/attendee/SignInAttendee";
import SignupAttendee from "./pages/general/attendee/SignUpAttendee";
import ForgotFacilitatorPassword from "./pages/general/facilitator/passwordScreens/ForgotFacilitatorPassword";
import ResetFacilitatorPassword from "./pages/general/facilitator/passwordScreens/ResetFacilitatorPassword";
import SuccessPassword from "./pages/general/facilitator/passwordScreens/SuccessPassword";
import SigninFacilitator from "./pages/general/facilitator/signin/SigninFacilitator";
import SignupFacilitator from "./pages/general/facilitator/signup/SignupFacilitator";
import Success from "./pages/general/facilitator/verifyOtp/Success";
import VerifyOtp from "./pages/general/facilitator/verifyOtp/VerifyOtp";
import LandingPage from "./pages/general/landingPage/LandingPage";
import WelcomeBack from "./pages/general/welcome/WelcomeBack";
import PrivateRoute from "./components/PrivateRoute";
import AOS from "aos";
import "aos/dist/aos.css";
import FacilitatorDashboard from "./pages/private/facilitator/FacilitatorDashboard";
import AttendeeDashboard from "./pages/private/attendee/AttendeeDashboard";
import FacilitatorLectures from "./pages/private/facilitator/FacilitatorLectures";
import FacilitatorInvites from "./pages/private/facilitator/FcailitatotInvites";
import FacilitatorProfile from "./pages/private/facilitator/FacilitatorProfile";
import FacilitatorEditProfile from "./pages/private/facilitator/FcailitatorEditProfile";
import LectureDetails from "./pages/private/facilitator/LectureDetails";
import AttendeeHistory from "./pages/private/attendee/AttendeeHistory";
import AttendeeProfile from "./pages/private/attendee/AttendeeProfile";
import AttendeeEditProfile from "./pages/private/attendee/AttendeeEditProfile";
import ViewLecture from "./pages/private/attendee/ViewLecture";
import ClassFeeds from "./pages/private/facilitator/classroom/ClassFeeds";
import RankedQuestions from "./pages/private/facilitator/classroom/RankedQuestions";
import AnsweredQuestions from "./pages/private/facilitator/classroom/AnsweredQuestions";
import PostQuestion from "./pages/private/attendee/classroom/PostQuestion";
import UpvotedQuestions from "./pages/private/attendee/classroom/UpvotedQuestions";
import AttendeeAnsQuestions from "./pages/private/attendee/classroom/AttendeeAnsQuestions";

AOS.init();

interface AppProps {}

const App: React.FunctionComponent<AppProps> = () => {
  return (
    <div>
      <BrowserRouter>
        {/* Public Routes  */}
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/welcome-back" element={<WelcomeBack />} />
          <Route path="/facilitator/signin" element={<SigninFacilitator />} />
          <Route path="/facilitator/signup" element={<SignupFacilitator />} />
          <Route path="/attendee/signin" element={<SigninAttendee />} />
          <Route path="/attendee/signup" element={<SignupAttendee />} />
          <Route path="/verify-account/:email" element={<VerifyOtp />} />
          <Route path="/account-verified" element={<Success />} />
          <Route
            path="/forgot-password"
            element={<ForgotFacilitatorPassword />}
          />
          <Route
            path="/reset-password"
            element={<ResetFacilitatorPassword />}
          />
          <Route path="/reset-password/success" element={<SuccessPassword />} />

          {/* Private Routes  */}
          <Route element={<PrivateRoute />}>
            <Route
              path="/facilitator/dashboard"
              element={<FacilitatorDashboard />}
            />
            <Route
              path="/facilitator/lectures"
              element={<FacilitatorLectures />}
            />
            <Route
              path="/facilitator/invites"
              element={<FacilitatorInvites />}
            />
            <Route
              path="/facilitator/profile"
              element={<FacilitatorProfile />}
            />
            <Route
              path="/facilitator/edit-profile"
              element={<FacilitatorEditProfile />}
            />
            <Route
              path="/facilitator/classroom/feeds/:id"
              element={<ClassFeeds />}
            />
            <Route
              path="/facilitator/classroom/ranked-questions/:id"
              element={<RankedQuestions />}
            />
            <Route
              path="/facilitator/classroom/answered-questions/:id"
              element={<AnsweredQuestions />}
            />
            <Route
              path="/facilitator/lecture/:id"
              element={<LectureDetails />}
            />
            <Route path="/attendee/dashboard" element={<AttendeeDashboard />} />
            <Route path="/attendee/history" element={<AttendeeHistory />} />
            <Route path="/attendee/profile" element={<AttendeeProfile />} />
            <Route
              path="/attendee/edit-profile"
              element={<AttendeeEditProfile />}
            />
            <Route path="/attendee/lecture/:id" element={<ViewLecture />} />
            <Route
              path="/attendee/classroom/feed/:id"
              element={<PostQuestion />}
            />
            <Route
              path="/attendee/classroom/upvoted-questions/:id"
              element={<UpvotedQuestions />}
            />
            <Route
              path="/attendee/classroom/ans-questions/:id"
              element={<AttendeeAnsQuestions />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
