import FacilitatorSidebar from "../../../components/FacilitatorSidebar/FacilitatorSidebar";
import avatar from "../../../images/defaultPic.png";
import { Input, notification, Switch } from "antd";
import { useNavigate } from "react-router-dom";
import { selectUser, logOutUser } from "../../../store/User";
import { useSelector, useDispatch } from "react-redux";
import { logout, selectAccessToken } from "../../../store/Auth";
import axios from "axios";
import { useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";

interface FacilitatorProfileProps {}

const FacilitatorProfile: React.FunctionComponent<
  FacilitatorProfileProps
> = () => {
  const onChange = (checked: boolean) => {
    console.log(`switch to ${checked}`);
  };
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector(selectAccessToken);
  const [isPLoading, setIsPLoading] = useState<boolean>(false);

  const logoutFacilitator = () => {
    dispatch(logout(null));
    dispatch(logOutUser(null));
  };

  const deactivateUser = () => {
    setIsPLoading(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/users/deactivate`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setIsPLoading(false);
        notification.success({
          message: "User deactivated",
          description: "Your account has been deactivated successfully",
        });
        logoutFacilitator();
      })
      .catch((err) => {
        setIsPLoading(false);
        notification.error({
          message: "Error deactivating account",
          description: "Oops! there was an error deactivating your account",
        });
      });
  };

  return (
    <div className="dashCont">
      <FacilitatorSidebar />
      <div className="flexMain">
        <div className="fstMain">
          <div className="topFstMain">
            <img
              src={
                user.profile.picture !== null ? user.profile.picture : avatar
              }
              alt=""
              className="profilePic"
            />
            <h1>{`${user.first_name} ${user.last_name}`}</h1>
            <p>{`${user.email}`}</p>
            <button
              className="edit"
              onClick={() => navigate("/facilitator/edit-profile")}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 20H20"
                  stroke="#14B8A6"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8 19.9998V20.9998C8.26522 20.9998 8.51957 20.8945 8.70711 20.707L8 19.9998ZM4 19.9998H3C3 20.5521 3.44772 20.9998 4 20.9998V19.9998ZM4 15.9998L3.29289 15.2927C3.10536 15.4803 3 15.7346 3 15.9998H4ZM15.2929 4.70696L16 5.41406L16 5.41406L15.2929 4.70696ZM16.7071 4.70696L16 5.41406L16 5.41406L16.7071 4.70696ZM19.2929 7.29274L20 6.58564V6.58564L19.2929 7.29274ZM19.2929 8.70696L18.5858 7.99985L19.2929 8.70696ZM8 18.9998H4V20.9998H8V18.9998ZM5 19.9998V15.9998H3V19.9998H5ZM4.70711 16.707L16 5.41406L14.5858 3.99985L3.29289 15.2927L4.70711 16.707ZM16 5.41406L18.5858 7.99985L20 6.58564L17.4142 3.99985L16 5.41406ZM18.5858 7.99985L7.29289 19.2927L8.70711 20.707L20 9.41406L18.5858 7.99985ZM18.5858 7.99985V7.99985L20 9.41406C20.781 8.63302 20.781 7.36669 20 6.58564L18.5858 7.99985ZM16 5.41406H16L17.4142 3.99985C16.6332 3.2188 15.3668 3.2188 14.5858 3.99985L16 5.41406Z"
                  fill="#14B8A6"
                />
              </svg>
              <span>Edit Profile</span>
            </button>
          </div>
          <div className="topFstMain pdTp">
            <div className="formField">
              <label htmlFor="timeZone">LOCATION/TIME ZONE</label>
              <select name="timeZone" id="timeZone">
                <option value="">Set Location/Time Zone</option>
                <option value="">GMT</option>
                <option value="">WAT</option>
              </select>
            </div>
            <div className="formField">
              <label htmlFor="lang">LANGUAGE</label>
              <select name="lang" id="lang">
                <option value="">Select preferred language</option>
                <option value="">Yoruba</option>
                <option value="">Igbo</option>
              </select>
            </div>
          </div>
          <div className="lastMn">
            <div className="formField">
              <label htmlFor="password">PASSWORD</label>
              <Input.Password />
            </div>
            <button>Change Password</button>
          </div>
        </div>
        <div className="scdMain">
          <div className="notifcation">
            <div className="tpNtf">
              <h2>Notification settings</h2>
              <div className="toggCont">
                <span>Toggle all notifications</span>
                <Switch size="small" onChange={onChange} />
              </div>
            </div>
            <div className="btNtf">
              <h3>Notify me when:</h3>
              <div className="toggCont mgtP">
                <span>It is 1 hour to a scheduled lecture time</span>
                <Switch size="small" defaultChecked onChange={onChange} />
              </div>
              <div className="toggCont mgtP">
                <span>There are question entries yet to be answered</span>
                <Switch size="small" defaultChecked onChange={onChange} />
              </div>
              <div className="toggCont mgtP">
                <span>Student/ Attendee accepts invite</span>
                <Switch size="small" onChange={onChange} />
              </div>
            </div>
          </div>
          <div className="notifcation mgtP">
            <h2>Data sharing permissions</h2>
            <p>Give permission to what data gets shared</p>
            <div className="toggCont mgtP">
              <span>Display my full name</span>
              <Switch size="small" defaultChecked onChange={onChange} />
            </div>
            <div className="toggCont mgtP">
              <span>Share my location/ time zone</span>
              <Switch size="small" defaultChecked onChange={onChange} />
            </div>
            <div className="toggCont mgtP">
              <span>Share my profile info</span>
              <Switch size="small" onChange={onChange} />
            </div>
          </div>
          <button onClick={logoutFacilitator} className="logout">
            Log Out
          </button>
          <button onClick={deactivateUser} className="deactivate">
            {!isPLoading ? "Deactivate Account" : <LoadingOutlined />}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FacilitatorProfile;
